/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import { InputField, PlusButton } from '../ArrayItemControl';
import { ArrayItems, DeleteIcon, Textarea } from '../contentTypeRendererv2';
import { postData } from '../../../utils/axios';
import styled from 'styled-components';

const Label = styled.label`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0.5rem;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const PreviewSelected = styled.img`
  width: 100%;
  max-width: 100px;
  object-fit: cover;
`;

interface Iprops {
  handleFilledData: any;
  propertyName: string;
  defaultValue: any;
  single: boolean;
}

const ArrayOfTextLongTextMediaControl: React.FC<Iprops> = ({
  handleFilledData,
  propertyName,
  defaultValue,
  single,
}) => {
  const [count, setCount] = useState(1);
  const [data, setData] = useState<any>([]);
  const [sources, setSources] = useState<any>([]);

  useEffect(() => {
    handleFilledData(propertyName, data, true);
  }, [data]);

  const dataHandler = (index: number, value: any, id: string) => {
    if (!single) {
      let obj = {};
      obj = { ...data[index], [id]: value };
      const newData = [...data];
      newData[index] = obj;
      return setData(newData);
    }
    // When it is a single string select
    setData(value);
  };
  useEffect(() => {
    if (defaultValue?.length || defaultValue) {
      // Default Value
      setCount(defaultValue?.length || 1);
      setData(defaultValue);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await postData('/getResourceGroup', {});
        setSources(result?.data);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);

  console.log(data);

  return (
    <ArrayItems>
      {new Array(count)?.fill(null).map((t: any, index: number) => (
        <>
          <Select
            value={single ? data : data?.[index]?.media}
            key={index}
            onChange={(e) => dataHandler(index, e?.target?.value, 'media')}
          >
            <option value="" disabled>
              Select an option
            </option>
            {sources.map((option: any, index: number) => (
              <option key={index} value={option.url}>
                {option.name}
              </option>
            ))}
          </Select>
          <PreviewSelected src={single ? data : data?.[index]?.media} alt="" />
          <InputField
            value={data?.[index]?.text}
            key={index}
            onChange={(e) => dataHandler(index, e?.target?.value, 'text')}
            placeholder="text"
          />
          <Textarea
            value={data?.[index]?.long_text}
            key={index}
            onChange={(e) => dataHandler(index, e?.target?.value, 'long_text')}
            placeholder="long text"
          />
        </>
      ))}
      {!single && (
        <>
          <PlusButton onClick={() => setCount(count + 1)}>+</PlusButton>
          <DeleteIcon
            onClick={() => {
              if (count > 1) {
                setCount(count - 1);
                const newData = [...data];
                newData.pop();
                setData(newData);
              }
            }}
          >
            -
          </DeleteIcon>
        </>
      )}
    </ArrayItems>
  );
};

export default ArrayOfTextLongTextMediaControl;
