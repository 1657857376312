/* eslint-disable styled-components-a11y/click-events-have-key-events */
/* eslint-disable styled-components-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0fr;
  gap: 1rem;
  margin-top: 1rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 0fr;
  align-items: center;
  margin-bottom: 10px;
  gap: 1rem;
`;

const DeleteIcon = styled.span`
  cursor: pointer;
  color: #6e6e6e;
  font-size: 3rem;
  &:hover {
    opacity: 0.7;
  }
`;

const Button = styled.button`
  margin-top: 10px;
  background: none;
  cursor: pointer;
  color: #6e6e6e;
  font-size: 1.6rem;
  &:hover {
    opacity: 0.7;
  }
`;

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin-bottom: 10px;
  gap: 1rem;
`;

const Label = styled.p`
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: #1d1d1d;
  ::first-letter {
    text-transform: uppercase;
  }
`;

interface OptionType {
  label: string;
  value: string;
}

interface ContentControl extends ControlProps {
  data: string[];
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const MultiTargetRenderer: React.FC<ContentControl> = ({
  data,
  handleChange,
  path,
  id,
}) => {
  const [inputs, setInputs] = useState<string[]>(data || []);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [dynamicQuery, result] = useDynamicQueryMutation();

  // const options: OptionType[] = [
  //   { value: 'tag1', label: 'Tag 1' },
  //   { value: 'tag2', label: 'Tag 2' },
  //   { value: 'tag3', label: 'Tag 3' },
  //   // Add your own options here
  // ];

  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: '/getTags',
        method: 'POST',
        body: {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchOptions();
  }, []);

  React.useEffect(() => {
    if (result.status === 'fulfilled') {
      setOptions(
        result?.data?.data?.map((t: any) => ({
          value: t?.tag,
          label: t?.tag,
        }))
      );
    }
  }, [result]);

  const handleSelectChange = (
    selectedOption: OptionType | null,
    index: number
  ) => {
    const newInputs = [...inputs];
    newInputs[index] = selectedOption ? selectedOption.value : '';
    setInputs(newInputs);
    handleChange(path, newInputs);
  };

  const handleAddInput = () => {
    setInputs([...inputs, '']);
  };

  const handleDeleteInput = (index: number) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
    handleChange(path, newInputs);
  };

  return (
    <Wrapper>
      <Label>{path}</Label>
      <Button type="button" onClick={handleAddInput}>
        +
      </Button>
      <Inputs>
        {inputs.map((input, index) => (
          <Container key={index}>
            <Select
              value={options.find((option) => option.value === input)}
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption as OptionType | null, index)
              }
              options={options}
              // make font size same as label
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: '1rem',
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  lineHeight: 1.6,
                  letterSpacing: '0.0075em',
                  color: '#1d1d1d',

                  '&:hover': {},
                  '&:focus': {},
                }),
                menu: (provided) => ({
                  ...provided,
                  fontSize: '1rem',
                }),
                option: (provided) => ({
                  ...provided,
                  fontSize: '1rem',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  fontSize: '1rem',
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: '1rem',
                }),
                input: (provided) => ({
                  ...provided,
                  fontSize: '1rem',
                }),
              }}
            />
            <DeleteIcon onClick={() => handleDeleteInput(index)}>-</DeleteIcon>
          </Container>
        ))}
      </Inputs>
    </Wrapper>
  );
};

export default withJsonFormsControlProps(MultiTargetRenderer);
