/* eslint-disable styled-components-a11y/no-noninteractive-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */
import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { ChangeEvent, useRef, useState } from 'react';
import { uploadImageWithFile } from '../../utils/uploadImage';
import styled from 'styled-components';

interface ImageUploadControlProps {
  data: string;
  handleChange: (path: string, value: string) => void;
  path: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
`;

const FileInput = styled.input`
  display: none;
`;

const Button = styled.label`
  background-color: #f0f0f0;
  color: #333;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
`;

const FileName = styled.span`
  color: #666;
  font-size: 12px;
  margin-top: 5px;
`;

const LoadingText = styled.span`
  color: #999;
  font-size: 12px;
  margin-top: 5px;
`;

const ImageUploadControl: React.FC<ImageUploadControlProps> = ({
  data,
  handleChange,
  path,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>(
    data ? 'Image Uploaded' : 'Upload a logo'
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setLoading(true);
      setFileName('Loading...');
      const imageUrl = await uploadImageAndGetUrl(file);
      handleChange(path, imageUrl);
      setLoading(false);
      setFileName('Image Uploaded');
    } else {
      handleChange(path, '');
      setFileName('No files selected');
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      // fileInputRef.current.click();
    }
  };

  return (
    <Container>
      <Image src={data} alt="" />
      <Button htmlFor="file-input" onClick={handleButtonClick}>
        Choose File
      </Button>
      {loading ? (
        <LoadingText>Loading...</LoadingText>
      ) : (
        <FileName>{fileName}</FileName>
      )}
      <FileInput
        id="file-input"
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </Container>
  );
};

async function uploadImageAndGetUrl(file: File): Promise<string> {
  // Implement your own image uploading functionality here
  // This should upload 'file' to your server and return the URL of the uploaded image
  const formData = new FormData();
  formData.append('file', file);
  const img = await uploadImageWithFile(formData);
  return img;
}

const ImageUploadControlWithProps =
  withJsonFormsControlProps(ImageUploadControl);
export { ImageUploadControlWithProps as ImageUploadControl };
