/* eslint-disable styled-components-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { DeleteIcon, InputField, PlusButton } from './ArrayItemControl';

const Wrapper = styled.label`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`;

const InputWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr auto;
  gap: 2rem;
  height: 100%;
`;

const Delete = styled.button`
  background: 0;
  cursor: pointer;
  margin-bottom: 1rem;
  &:hover {
    opacity: 0.7;
  }
`;

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

interface ContentControl extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const PickArgs: React.FC<ContentControl> = ({
  data,
  handleChange,
  path,
  id,
}) => {
  const [inputList, setInputList] = useState<any>(['']);

  const handleContentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedInputList = [...inputList];
    updatedInputList[index] = event.target.value;
    setInputList(updatedInputList);
    handleChange(path, updatedInputList);
  };

  const handleRemoveInput = (index: number) => {
    const updatedInputList = [...inputList];
    updatedInputList.splice(index, 1);
    setInputList(updatedInputList);
    handleChange(path, updatedInputList);
  };

  const handleAddInput = () => {
    setInputList([...inputList, '']);
  };

  useEffect(() => {
    setInputList(data || ['']);
  }, [data]);

  return (
    <Wrapper>
      <Label htmlFor={id}>Input Args</Label>
      {inputList.map((item: any, index: number) => (
        <InputWrapper key={index}>
          <InputField
            placeholder="Argument Name"
            type="text"
            onChange={(event) => handleContentChange(event, index)}
            value={item?.toLocaleLowerCase()?.replaceAll(' ', '_')}
          />
          <Delete type="button" onClick={() => handleRemoveInput(index)}>
            Delete
          </Delete>
        </InputWrapper>
      ))}
      <PlusButton type="button" onClick={handleAddInput}>
        +
      </PlusButton>
    </Wrapper>
  );
};

export default withJsonFormsControlProps(PickArgs);
