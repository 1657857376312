/* eslint-disable styled-components-a11y/no-static-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0fr;
  gap: 1rem;
  margin-top: 1rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0fr;
  align-items: center;
  margin-bottom: 10px;
  gap: 1rem;
`;

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin-bottom: 10px;
  gap: 1rem;
`;

const Label = styled.p`
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: #1d1d1d;
  ::first-letter {
    text-transform: uppercase;
  }
`;

const InputField = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 38px;
`;

const DeleteIcon = styled.span`
  cursor: pointer;
  color: #6e6e6e;
  font-size: 3rem;
  &:hover {
    opacity: 0.7;
  }
`;

const Button = styled.button`
  margin-top: 10px;
  background: none;
  cursor: pointer;
  color: #6e6e6e;
  font-size: 1.6rem;
  &:hover {
    opacity: 0.7;
  }
`;

interface OptionType {
  label: string;
  value: string;
}

interface InputType {
  dataType: string;
  propertyName: string;
}

interface ContentControl extends ControlProps {
  data: InputType[];
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const MultiTagRenderer: React.FC<ContentControl> = ({
  data,
  handleChange,
  path,
  id,
}) => {
  const [inputs, setInputs] = useState<InputType[]>(data || []);
  const [options, setOptions] = useState<OptionType[]>([
    {
      label: 'text',
      value: 'text',
    },
    {
      label: 'long text',
      value: 'longText',
    },
    {
      label: 'media',
      value: 'media',
    },
    {
      label: 'Array of {text} control',
      value: 'arrayOfTextControl',
    },
    {
      label: 'Array of {long text} control',
      value: 'arrayOfLongTextControl',
    },
    {
      label: 'Array of {media} control',
      value: 'arrayOfMediaControl',
    },
    {
      label: 'Array of {text,text} control',
      value: 'arrayOfTextTextControl',
    },
    {
      label: 'Array of {text,long text} control',
      value: 'arrayOfTextLongTextControl',
    },
    {
      label: 'Array of {text,media} control',
      value: 'arrayOfTextMediaControl',
    },
    {
      label: 'Array of {long text, media} control',
      value: 'arrayOfLongTextMediaControl',
    },
    {
      label: 'Array of {text,text,long text} control',
      value: 'arrayOfTextTextLongTextControl',
    },
    {
      label: 'Array of {text,text,media} control',
      value: 'arrayOfTextTextMediaControl',
    },
    {
      label: 'Array of {text,long text,media} control',
      value: 'arrayOfTextLongTextMediaControl',
    },
    {
      label: 'Array of {text,text,long text,media} control',
      value: 'arrayOfTextTextLongTextMediaControl',
    },
  ]);
  const [dynamicQuery, result] = useDynamicQueryMutation();

  // Fetch and Effect Hooks remain the same...

  const handleSelectChange = (
    selectedOption: OptionType | null,
    index: number
  ) => {
    if (index >= 0 && index < inputs.length) {
      // Check index range
      const newInputs = [...inputs];
      const updatedInput = { ...newInputs[index] }; // Make a shallow copy of the object

      updatedInput.dataType = selectedOption ? selectedOption.value : '';
      newInputs[index] = updatedInput; // Replace the old object with the updated one

      setInputs(newInputs);
      handleChange(path, newInputs);
    } else {
      console.error('Index out of range');
    }
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newInputs = JSON.parse(JSON.stringify(inputs)); // Deep clone
    newInputs[index].propertyName = event.target.value?.replaceAll(' ', '_');
    setInputs(newInputs);
    handleChange(path, newInputs);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { dataType: '', propertyName: '' }]);
  };

  const handleDeleteInput = (index: number) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
    handleChange(path, newInputs);
  };

  return (
    <Wrapper>
      <Label>{path}</Label>
      <Button type="button" onClick={handleAddInput}>
        +
      </Button>
      <Inputs>
        {inputs.map((input, index) => (
          <Container key={index}>
            <Select
              placeholder="Select a data type"
              value={options.find((option) => option.value === input.dataType)}
              styles={{
                placeholder: (base) => ({
                  ...base,
                  color: '#6e6e6e',
                  fontSize: '1rem',
                }),
                option: (base) => ({
                  ...base,
                  color: '#6e6e6e',
                  fontSize: '1rem',
                }),
              }}
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption as OptionType | null, index)
              }
              options={options}
              // Style code here...
            />
            <InputField
              defaultValue={input.propertyName}
              placeholder="Property Name"
              onChange={(event) => handleTextChange(event, index)}
            />
            <DeleteIcon onClick={() => handleDeleteInput(index)}>-</DeleteIcon>
          </Container>
        ))}
      </Inputs>
    </Wrapper>
  );
};

export default withJsonFormsControlProps(MultiTagRenderer);
