/* eslint-disable styled-components-a11y/no-onchange */
/* eslint-disable styled-components-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { DeleteIcon, InputField, PlusButton } from './ArrayItemControl';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

const Wrapper = styled.label`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`;

const InputWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr auto auto;
  gap: 2rem;
  height: 100%;
`;

const Select: any = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 38px;
  margin-bottom: 1rem;
`;

const Delete = styled.button`
  background: 0;
  cursor: pointer;
  margin-bottom: 1rem;
  &:hover {
    opacity: 0.7;
  }
`;

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

interface ContentControl extends ControlProps {
  data: any;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const PickArgumentsRenderer: React.FC<ContentControl> = ({
  data,
  handleChange,
  path,
  id,
}) => {
  const [inputList, setInputList] = useState<any>(['']);

  useEffect(() => {
    if (data) {
      const initialInputList = Object.keys(data).map((key) => ({
        value: data[key].value,
        type: data[key].type,
      }));
      setInputList(initialInputList);
    } else {
      setInputList([{ value: '', type: '' }]);
    }
  }, [data]);
  const handleContentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = event.target.value.toLocaleLowerCase().replaceAll(' ', '_');
    const updatedInputList = [...inputList];
    updatedInputList[index] = { ...updatedInputList[index], value };
    setInputList(updatedInputList);
    handleChange(path, updatedInputList);
  };

  const handleTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const newType = event.target.value;
    const updatedInputList = [...inputList];
    updatedInputList[index] = { ...updatedInputList[index], type: newType };
    setInputList(updatedInputList);
    handleChange(path, updatedInputList);
  };

  const handleRemoveInput = (index: number) => {
    const updatedInputList = [...inputList];
    updatedInputList.splice(index, 1);
    setInputList(updatedInputList);
    handleChange(path, updatedInputList);
  };
  const handleAddInput = () => {
    setInputList([...inputList, { value: '', type: '' }]);
  };
  useEffect(() => {
    setInputList(data || ['']);
  }, [data]);

  return (
    <Wrapper>
      <Label htmlFor={id}>Arguments</Label>
      {inputList.map((item: any, index: number) => (
        <InputWrapper key={index}>
          <InputField
            placeholder="Argument Name"
            type="text"
            onChange={(event) => handleContentChange(event, index)}
            value={item?.value || ''}
          />
          <Select
            placeholder="Type"
            onChange={(event: any) => handleTypeChange(event, index)}
            value={item?.type || ''}
          >
            <option value="">Select a type...</option>
            <option value="string">String</option>
            <option value="boolean">Boolean</option>
            <option value="number">Number</option>
          </Select>

          <Delete type="button" onClick={() => handleRemoveInput(index)}>
            Delete
          </Delete>
        </InputWrapper>
      ))}
      <PlusButton type="button" onClick={handleAddInput}>
        +
      </PlusButton>
    </Wrapper>
  );
};

export default withJsonFormsControlProps(PickArgumentsRenderer);
