import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, isControl, scopeEndsWith, and } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers } from '@jsonforms/material-renderers';

export const customAccountManagerTester = rankWith(
  3, // rank should be higher than the default renderers
  and(isControl, scopeEndsWith('account_manager')) // replace 'yourPath' with the appropriate value
);
