/* eslint-disable styled-components-a11y/no-noninteractive-element-interactions */
/* eslint-disable styled-components-a11y/no-static-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import styled from 'styled-components';
import { exportData } from '../../utils/exportJson';
import { exportDataToCSV } from '../../utils/exportCvs';
import ImportCVS from '../importCVS';

const Wrapper = styled.div``;
const Icon = styled.img`
  max-width: 20px;
  cursor: pointer;
`;
const Action = styled.button``;

interface IDotMenu {
  fetchedData: any;
  cvsEndpoint: any;
}

const DropdownWrapper = styled.div`
  display: grid;
  width: 50px;
  position: relative;
  padding: 0 15px;
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
  z-index: 1;
`;

const DropdownAction = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`;

const DotMenu: React.FC<IDotMenu> = ({ fetchedData, cvsEndpoint }) => {
  const [openMenuIndex, setOpenMenuIndex] = React.useState<number | null>(null);
  const wrapperRef: any = useRef(null);
  const { authenticated } = useAppSelector(selectGlobal);
  const userRole = authenticated?.user?.userRole?.toLowerCase();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenMenuIndex(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dotMenu = authenticated?.user?.pages?.find(
    (p: any) => p?.pageSettings
  )?.dotMenu;

  const toggleDropdown = (index: number) => {
    if (openMenuIndex === index) {
      setOpenMenuIndex(null);
    } else {
      setOpenMenuIndex(index);
    }
  };

  return (
    <DropdownWrapper ref={wrapperRef}>
      {dotMenu?.map(
        (dot: any, index: number) =>
          dot?.permissions?.includes(userRole) && (
            <>
              <Icon
                alt=""
                src={dot?.icon}
                onClick={() => toggleDropdown(index)}
              />
              <DropdownMenu isOpen={openMenuIndex === index}>
                {dot?.actions?.find(
                  (act: any) => act?.value === 'export_json'
                ) && (
                  <DropdownAction onClick={() => exportData(fetchedData)}>
                    Export JSON
                  </DropdownAction>
                )}
                {dot?.actions?.find(
                  (act: any) => act?.value === 'import_cvs'
                ) && (
                  <DropdownAction>
                    <ImportCVS cvsEndpoint={cvsEndpoint} />
                  </DropdownAction>
                )}
                {dot?.actions?.find(
                  (act: any) => act?.value === 'export_cvs'
                ) && (
                  <DropdownAction onClick={() => exportDataToCSV(fetchedData)}>
                    Export CVS
                  </DropdownAction>
                )}
              </DropdownMenu>
            </>
          )
      )}
    </DropdownWrapper>
  );
};

export default DotMenu;
