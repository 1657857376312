import React, { useEffect } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import { withJsonFormsControlProps } from '@jsonforms/react';

const Label = styled.label`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0.5rem;
`;

const DropdownContainer = styled.div`
  padding: 1rem 0;
  .react-select__control {
    background-color: white;
    border-radius: 0;
    padding: 4px;
    font-size: 14px;
    min-height: 38px;
    &:hover {
      border-color: #d9d9d9;
    }
  }
  .react-select__menu {
    margin-top: 0;
    border-radius: 0;
    z-index: 5;
  }
  .react-select__option--is-focused {
    background-color: #f0f0f0;
  }
  .react-select__option--is-selected {
    background-color: blue;
    color: white;
  }
`;

const options = [
  {
    value: 'Static Classes with single selection',
    label: 'Static Classes with single selection',
  },
  {
    value: 'Static Classes with multiple selection',
    label: 'Static Classes with multiple selection',
  },
  {
    value: 'Dynamic Classes with single selection',
    label: 'Dynamic Classes with single selection',
  },
  {
    value: 'Dynamic Classes with multiple selection',
    label: 'Dynamic Classes with multiple selection',
  },
];

const CustomFunctionTypeDropdown = (props: any) => {
  const state = useAppSelector(selectGlobal);
  const currentFunctionType = state?.currentFormState?.function_type;

  // const functionType = state.functionTypeOnCondition;

  // useEffect(() => {
  //   props.handleChange(props.path, functionType);
  // }, [functionType]);

  return currentFunctionType === 'classify' ||
    currentFunctionType === 'decide' ||
    currentFunctionType === 'insights' ? (
    <DropdownContainer>
      <Label>{props.label}</Label>
      <Select
        value={{ value: props.data, label: props.data }}
        options={options}
        placeholder="Select function type"
        onChange={(selectedOption: any) => {
          props.handleChange(props.path, selectedOption.value);
        }}

        // Any other props that you may want to pass into the Select component
      />
    </DropdownContainer>
  ) : (
    <DropdownContainer />
  );
};

export default withJsonFormsControlProps(CustomFunctionTypeDropdown);
