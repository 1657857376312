/* eslint-disable styled-components-a11y/no-static-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */
/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml';
import { ControlProps, updateErrors } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import cheerio from 'cheerio';
import {
  selectGlobal,
  setCurrentHTML,
  setFormErrorsRedux,
} from '../../redux/state/globalSlice';
import { postData } from '../../utils/axios';

const HTMLContainer = styled.div`
  max-width: 600px;
`;

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const Title = styled.h2`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1.6rem;
  margin: 1rem 0;
`;

const Validator = styled.p`
  color: #fb4c4c;
`;

const Dropdown = styled.div`
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1;
  width: 100%;
`;

const DropdownItem = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  img {
    margin-right: 10px;
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 1rem;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  width: 100%;
  height: 35px;
  text-align: left;
  padding-left: 10px;
  cursor: pointer;

  &:hover {
    background-color: #e6e6e6;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownMenu = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;
  border: 1px solid #ccc;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  padding: 10px 15px;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 1rem;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Option = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const MissingTitle = styled.p`
  font-weight: 700;
  color: #333;
  margin-top: 1rem;
`;
const MissingSubTitle = styled.p`
  font-size: 1.1rem;
  color: #333;
`;

const MissingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  margin: 2rem 0;
  border-top: 1px solid #33333329;
  padding-top: 2rem;
`;

const InContainer = styled.div`
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  height: 50px;
`;

interface HtmlEditorProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

interface Sources {
  name: string;
  url: string;
}

function validateHtmlWithCss(input: string): boolean {
  // Check if input includes <style> tag
  const hasStyleTag = /<style[\s\S]*?>[\s\S]*?<\/style>/i.test(input);

  // Validate that the input is HTML
  const doc = new DOMParser().parseFromString(input, 'text/html');
  const isValidHtml = Array.from(doc.body.childNodes).some(
    (node) => node.nodeType === 1
  );

  return hasStyleTag && isValidHtml;
}

const CustomHTMLObject: React.FC<HtmlEditorProps> = (props) => {
  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [dynamicQuery, result] = useDynamicQueryMutation();
  const [htmlValidated, setHtmlValidated] = useState(true);
  const global = useAppSelector(selectGlobal);
  const dispatch = useAppDispatch();
  const [noMatch, setNoMatch] = useState<any>([]);
  const [replacedSrc, setReplacedSrc] = useState<any>([]);
  const [replacedHTML, setReplacedHTML] = useState<any>();
  const [sources, setSources] = useState<Sources[]>([]);
  const [selectedDropdown, setSelectedDropdown] = useState<number | null>(null);
  const [selectedReplacements, setSelectedReplacements] = useState<
    Map<string, string>
  >(new Map());

  function filterReplacedHTML(html: string): string {
    const $ = cheerio.load(html, { xmlMode: true });
    const removedElements = ['script', 'iframe', 'object', 'embed'];
    removedElements.forEach((tag) => {
      $(tag).remove();
    });
    return $.html();
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await postData('/getResourceGroup', {});
        setSources(result?.data);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);

  const replaceImgSrcInHtml = (htmlString: string) => {
    const $ = cheerio.load(htmlString, {
      xmlMode: true, // Enable XML mode to handle self-closing tags
    });
    const noMatch: any[] = [];
    const replacedSrc: any[] = [];

    $('img').each((index, element) => {
      const src = $(element).attr('src');
      const source = sources.find((source) => source.name === src);

      if (source) {
        $(element).attr('src', source.url);
        replacedSrc.push({ src, replacedWith: source.url });
      } else if (src) {
        noMatch.push({ src, replacedWith: '' });
      }
    });

    const htmlWithSelfClosingTags = $.html().replace(
      /<img([^>]+?)\/?>/g,
      '<img$1 />'
    );

    dispatch(setCurrentHTML(htmlWithSelfClosingTags));

    return {
      noMatch,
      replaced: htmlWithSelfClosingTags,
      replacedSrc,
    };
  };

  const replaceAndSetHTML = () => {
    const replacedData = replaceImgSrcInHtml(
      replacedHTML || props.data || global?.rowObject?.source
    );
    const filteredHTML = filterReplacedHTML(replacedData.replaced);
    setReplacedHTML(filteredHTML);
    dispatch(setCurrentHTML(filteredHTML));
    setNoMatch(replacedData.noMatch);
    setReplacedSrc(replacedData.replacedSrc);
    const finalHtml = filteredHTML.replace(/&#x24;/g, '$');
    const finalHtml2 = finalHtml.replace(/&quot;/g, '"');
    setReplacedHTML(finalHtml2);
  };

  const handleReplaceManually = () => {
    let html = replacedHTML || props.data || global?.rowObject?.source;
    selectedReplacements.forEach((value, key) => {
      html = html.replace(new RegExp(`src="${key}"`, 'g'), `src="${value}"`);
    });
    setReplacedHTML(html);
    dispatch(setCurrentHTML(html));
    updateHTMl(html);
  };

  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: '/partner_list',
        method: 'POST',
        body: {
          foundObject: global?.rowObject,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOptions();
    dispatch(
      setFormErrorsRedux({
        path: props.path,
        message: 'HTML cannot be empty',
      })
    );
  }, []);

  useEffect(() => {
    if (!global?.currentHTML?.length) {
      dispatch(
        setCurrentHTML(replacedHTML || props.data || global?.rowObject?.source)
      );
    }
  }, []);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      setOptions(result?.data?.data);
    }
  }, [result]);

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        props.handleChange(
          props.path,
          replacedHTML || (e.target?.result as string)
        );
      };

      reader.readAsText(file);
    }
  };

  const updateHTMl = (value: string) => {
    props.handleChange(props.path, value);
  };

  const toggleDropdown = (index: number) => {
    setSelectedDropdown(selectedDropdown === index ? null : index);
  };

  const selectReplacement = (src: string, replacementUrl: string) => {
    const updatedReplacements = new Map(selectedReplacements);
    updatedReplacements.set(src, replacementUrl);
    setSelectedReplacements(updatedReplacements);
    setSelectedDropdown(null);
  };

  const getSelectedReplacementName = (src: string) => {
    const selectedUrl = selectedReplacements.get(src) || '';
    const source = sources.find((source) => source.url === selectedUrl);
    return source ? source.name : 'Select replacement';
  };

  return (
    <div>
      <Label htmlFor={props.id}>HTML Editor</Label>
      <Validator>
        {htmlValidated ? '' : 'Please use only HTML and a CSS in style tag.'}
      </Validator>
      <HTMLContainer>
        <Title>Editor</Title>
        <CodeMirror
          options={{
            lineNumbers: true,
            mode: 'html',
          }}
          value={
            global.currentHTML ||
            replacedHTML ||
            props.data ||
            global?.rowObject?.source
          }
          onChange={(editor, data, value) => {
            dispatch(setCurrentHTML(value));
            if (validateHtmlWithCss(value)) {
              setHtmlValidated(true);
              dispatch(setFormErrorsRedux(''));
            } else {
              setHtmlValidated(false);
              dispatch(
                setFormErrorsRedux({
                  path: props.path,
                  message: 'HTML is not validated',
                })
              );
            }
            updateHTMl(value);
            setReplacedHTML(value); // Store the value but don't replace URLs yet
          }}
        />
      </HTMLContainer>
      {/* 
      {noMatch?.length > 0 && (
        <div>
          <MissingTitle>MISSING SOURCES</MissingTitle>
          <MissingSubTitle>
            The following resources are not available. Choose replacement
            resources in order to replace resource names with url.
          </MissingSubTitle>
          {Object.entries(
            noMatch?.reduce((acc: any, item: any) => {
              acc[item?.src] = (acc[item?.src] || 0) + 1;
              return acc;
            }, {}) || {}
          ).map(([src]: any, index: number) => (
            <MissingContainer key={index}>
              <MissingSubTitle>{src}</MissingSubTitle>
              <DropdownContainer>
                <DropdownButton onClick={() => toggleDropdown(index)}>
                  {getSelectedReplacementName(src)}
                </DropdownButton>
                {selectedDropdown === index && (
                  <DropdownMenu>
                    {sources.map((source, idx) => (
                      <Option
                        key={idx}
                        onClick={() => selectReplacement(src, source.url)}
                      >
                        <img src={source.url} alt={source.name} />
                        {source.name}
                      </Option>
                    ))}
                  </DropdownMenu>
                )}
              </DropdownContainer>
            </MissingContainer>
          ))}
        </div>
      )} */}
      {/* <Button onClick={replaceAndSetHTML}>Validate</Button> */}
      {/* <Button
        disabled={selectedReplacements.size === 0}
        onClick={handleReplaceManually}
      >
        Apply Changes
      </Button> */}
      <InContainer>
        <FileInputLabel htmlFor="fileUpload">
          {replacedHTML || props.data || global?.rowObject?.source
            ? 'Replace HTML'
            : 'Upload HTML'}
        </FileInputLabel>
        <FileInput
          id="fileUpload"
          type="file"
          accept=".html"
          onChange={handleUpload}
        />
      </InContainer>
    </div>
  );
};

export default withJsonFormsControlProps(CustomHTMLObject);
