import React from 'react';
import styled from 'styled-components';
import ImageUploadControlModified from './mediaControl';

export const InputContainer = styled.div`
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0fr;
  align-items: center;
  gap: 1rem;
`;

const WrapperColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 0rem;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 38px;
  margin-bottom: 1rem;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const DeleteIcon = styled.button`
  cursor: pointer;
  color: #6e6e6e;
  background: none;
  font-size: 2rem;
  &:hover {
    opacity: 0.7;
  }
`;

export const PlusButton = styled.button`
  background: none;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  background: #333;
  &:hover {
    opacity: 0.7;
  }
`;

const TextTextWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

interface ArrayItemControlProps {
  dataType: string;
  name: string;
  data: Array<{ values: string[] }>;
  handleDataChange: (
    valueIndex: number,
    subValueIndex: number,
    value: string
  ) => void;
  handleRemove: (valueIndex: number) => void;
  onAddClick: () => void;
}

interface ControlProps {
  values: string[];
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
}

const TextControl: React.FC<ControlProps> = ({ values, onChange }) => (
  <InputField
    placeholder="text..."
    type="text"
    value={values[0]}
    onChange={(event) => onChange(event, 0)}
  />
);

const TextTextControl: React.FC<ControlProps> = ({ values, onChange }) => (
  <TextTextWrapper>
    <InputField
      key={1}
      placeholder="text..."
      type="text"
      value={values[0] || ''}
      onChange={(event) => onChange(event, 0)}
    />
    <InputField
      key={2}
      placeholder="text..."
      type="text"
      value={values[1] || ''}
      onChange={(event) => onChange(event, 1)}
    />
  </TextTextWrapper>
);
const TextLongText: React.FC<ControlProps> = ({ values, onChange }) => (
  <WrapperColumn>
    <InputField
      key={1}
      placeholder="text..."
      type="text"
      value={values[0] || ''}
      onChange={(event) => onChange(event, 0)}
    />
    <Textarea
      key={2}
      placeholder="long text..."
      value={values[1]}
      onChange={(event) => onChange(event, 1)}
    />
  </WrapperColumn>
);

const TextTextLongText: React.FC<ControlProps> = ({ values, onChange }) => (
  <WrapperColumn>
    <InputField
      key={1}
      placeholder="text..."
      type="text"
      value={values[0] || ''}
      onChange={(event) => onChange(event, 0)}
    />
    <InputField
      key={2}
      placeholder="text..."
      type="text"
      value={values[1] || ''}
      onChange={(event) => onChange(event, 1)}
    />
    <Textarea
      key={3}
      placeholder="long text..."
      value={values[2]}
      onChange={(event) => onChange(event, 2)}
    />
  </WrapperColumn>
);

const TextMedia: React.FC<ControlProps> = ({ values, onChange }) => (
  <WrapperColumn>
    <InputField
      key={1}
      placeholder="text..."
      type="text"
      value={values[0] || ''}
      onChange={(event) => onChange(event, 0)}
    />
    <ImageUploadControlModified
      key={2}
      onChange={(event) => onChange(event, 1)}
      value={values[1]}
    />
  </WrapperColumn>
);

const TextTextMedia: React.FC<ControlProps> = ({ values, onChange }) => (
  <WrapperColumn>
    <InputField
      key={1}
      placeholder="text..."
      type="text"
      value={values[0] || ''}
      onChange={(event) => onChange(event, 0)}
    />
    <InputField
      key={2}
      placeholder="text..."
      type="text"
      value={values[1] || ''}
      onChange={(event) => onChange(event, 1)}
    />
    <ImageUploadControlModified
      key={2}
      onChange={(event) => onChange(event, 2)}
      value={values[2]}
    />
  </WrapperColumn>
);
const TextTextLongTextMedia: React.FC<ControlProps> = ({
  values,
  onChange,
}) => (
  <WrapperColumn>
    <InputField
      key={1}
      placeholder="text..."
      type="text"
      value={values[0] || ''}
      onChange={(event) => onChange(event, 0)}
    />
    <InputField
      key={2}
      placeholder="text..."
      type="text"
      value={values[1] || ''}
      onChange={(event) => onChange(event, 1)}
    />
    <Textarea
      key={3}
      placeholder="long text..."
      value={values[2] || ''}
      onChange={(event) => onChange(event, 2)}
    />
    <ImageUploadControlModified
      key={4}
      onChange={(event) => onChange(event, 3)}
      value={values[3]}
    />
  </WrapperColumn>
);

const Text: React.FC<ControlProps> = ({ values, onChange }) => (
  <InputField
    placeholder="text..."
    type="text"
    value={values[0]}
    onChange={(event) => onChange(event, 0)}
  />
);

const LongText: React.FC<ControlProps> = ({ values, onChange }) => (
  <Textarea
    placeholder="long text..."
    value={values[0]}
    onChange={(event) => onChange(event, 0)}
  />
);

const Media: React.FC<ControlProps> = ({ values, onChange }) => (
  <ImageUploadControlModified
    onChange={(event) => onChange(event, 0)}
    value={values[0]}
  />
);

const TextLongTextMedia: React.FC<ControlProps> = ({ values, onChange }) => (
  <WrapperColumn>
    <Textarea
      key={1}
      placeholder="long text..."
      value={values[0] || ''}
      onChange={(event) => onChange(event, 0)}
    />
    <InputField
      key={1}
      placeholder="text..."
      type="text"
      value={values[1] || ''}
      onChange={(event) => onChange(event, 1)}
    />
    <ImageUploadControlModified
      key={2}
      onChange={(event) => onChange(event, 2)}
      value={values[2]}
    />
  </WrapperColumn>
);

const LongTextMedia: React.FC<ControlProps> = ({ values, onChange }) => (
  <WrapperColumn>
    <Textarea
      key={1}
      placeholder="long text..."
      value={values[0]}
      onChange={(event) => onChange(event, 0)}
    />
    <ImageUploadControlModified
      onChange={(event) => onChange(event, 1)}
      value={values[1]}
    />
  </WrapperColumn>
);

const LongTextControl: React.FC<ControlProps> = ({ values, onChange }) => (
  <Textarea
    placeholder="long text..."
    value={values[0]}
    onChange={(event) => onChange(event, 0)}
  />
);

const MediaControl: React.FC<ControlProps> = ({ values, onChange }) => (
  <ImageUploadControlModified
    onChange={(event) => onChange(event, 0)}
    value={values[0]}
  />
);

const ArrayItemControl: React.FC<ArrayItemControlProps> = ({
  dataType,
  data,
  name,
  handleDataChange,
  handleRemove,
  onAddClick,
}) => {
  const ControlComponent =
    (dataType === 'arrayOfLongTextControl' && LongTextControl) ||
    (dataType === 'arrayOfTextTextControl' && TextTextControl) ||
    (dataType === 'arrayOfMediaControl' && MediaControl) ||
    (dataType === 'arrayOfTextLongTextControl' && TextLongText) ||
    (dataType === 'arrayOfTextTextLongTextControl' && TextTextLongText) ||
    (dataType === 'arrayOfLongTextMediaControl' && LongTextMedia) ||
    (dataType === 'arrayOfTextMediaControl' && TextMedia) ||
    (dataType === 'arrayOfTextTextMediaControl' && TextTextMedia) ||
    (dataType === 'arrayOfTextLongTextMediaControl' && TextLongTextMedia) ||
    (dataType === 'arrayOfTextTextLongTextMediaControl' &&
      TextTextLongTextMedia) ||
    (dataType === 'text' && Text) ||
    (dataType === 'longText' && LongText) ||
    (dataType === 'media' && Media) ||
    TextControl;

  return (
    <InputContainer>
      <h2>{name}</h2>
      {data.map((item, index) => (
        <Wrapper key={index}>
          <ControlComponent
            values={item.values}
            onChange={(event, subIndex) =>
              handleDataChange(index, subIndex, event.target.value)
            }
          />
          <DeleteIcon type="button" onClick={() => handleRemove(index)}>
            -
          </DeleteIcon>
        </Wrapper>
      ))}
      <PlusButton type="button" onClick={onAddClick}>
        +
      </PlusButton>
    </InputContainer>
  );
};

export default ArrayItemControl;
