import React from 'react';
import styled from 'styled-components';
import DynamicPopupDialog from './DynamicPopupDialog';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  selectGlobal,
  setShowJsonViewerPopup,
} from '../redux/state/globalSlice';

const CodeEditorContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #282c34;
  border-radius: 8px;
  color: #61dafb;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  overflow-x: auto;
`;

const formatJSON = (json: any) => {
  return json?.replace(/"(\w+)"\s*:/g, (_: any, match: any) => {
    return `<span style="color: #f08d49">"${match}"</span>:`;
  });
};

const JSONViewer = () => {
  const globalState = useAppSelector(selectGlobal);
  const dispatch = useAppDispatch();
  const json = formatJSON(JSON.stringify(globalState?.jsonViewerData, null, 2));

  return (
    <DynamicPopupDialog
      isOpen={globalState?.showJsonViewerPopup}
      onClose={() => dispatch(setShowJsonViewerPopup(false))}
    >
      <CodeEditorContainer>
        <pre dangerouslySetInnerHTML={{ __html: json }} />
      </CodeEditorContainer>
    </DynamicPopupDialog>
  );
};

export default JSONViewer;
