import React from 'react';
import styled from 'styled-components';
import { PageTitle } from '../../style/PageStyle';
import Circle from './Circle';
import Funnel from '../Funnel/Funnel';
import CandleStick from '../CandleStick';

const Wrapper = styled.div`
  display: flex;
  background: #eff9ff;
  align-items: center;
  border-radius: 10px;
  padding: 1.4rem 4.4rem 1.8rem 4.4rem;
  gap: 5rem;
`;

interface Stats {
  data: any;
  funnel: any;
  timeBased: any;
}

const Stat: React.FC<Stats> = ({ data, funnel, timeBased }) => {
  const [circleMode, setCircleMode] = React.useState(0);

  return (
    <Wrapper>
      <Circle
        circleMode={circleMode}
        funnel={funnel}
        setCircleMode={setCircleMode}
        totalOrg={data?.data?.length || 0}
        stats={data?.stats}
        totalUsers={
          data?.data?.length
            ? data?.data
                ?.map((dt: any) => dt?.['usersWithActiveLicenseCount'])
                .reduce((a: any, b: any) => a + b)
            : 0
        }
      />
      {timeBased ? (
        <CandleStick funnel={funnel} circleMode={circleMode} orgData={data} />
      ) : (
        <Funnel funnel={funnel} circleMode={circleMode} orgData={data} />
      )}
    </Wrapper>
  );
};

export default Stat;
