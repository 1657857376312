import React from 'react';
import styled from 'styled-components';
import { handleScore } from '../../utils/getHighestScore';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1.3rem;
`;
const Parent = styled.div`
  border-left: 1px solid #cce3f2;
  border-right: 1px solid #cce3f2;
  height: 415px;
  flex-grow: 1;
  position: relative;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Number = styled.p`
  display: flex;
  align-items: flex-end;
  position: relative;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding-bottom: 0.3rem;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(73, 85, 100, 0.8);
`;

const NumberGridLine = styled.div`
  position: absolute;
  width: 1070px;
  height: 1px;
  left: 26px;
  background: #cce3f2;
`;

const ColumnGridLine = styled.div`
  width: 1px;
  height: 408px;
  left: 26px;
  background: #cce3f2;
`;

const ColumnsContainer = styled.div`
  top: 0;
  z-index: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 13.5rem;
  width: 100%;
`;

interface Iprops {
  children: JSX.Element;
  obj: any;
}

const Background: React.FC<Iprops> = ({ children, obj }) => {
  const slicedNums = handleScore(obj, 'count').steps;
  return (
    <Wrapper>
      <Container>
        {slicedNums?.map((snum, index) => (
          <Number key={snum}>
            {snum}
            {index !== 0 && index !== 4 && <NumberGridLine />}
          </Number>
        ))}
      </Container>

      <Parent>
        {children}
        <ColumnsContainer>
          <ColumnGridLine />
          <ColumnGridLine />
          <ColumnGridLine />
          <ColumnGridLine />
          <ColumnGridLine />
          <ColumnGridLine />
          <ColumnGridLine />
        </ColumnsContainer>
      </Parent>
    </Wrapper>
  );
};

export default Background;
