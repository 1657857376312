import React from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import { useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
  align-content: center;
  justify-content: center;
  gap: 1.5rem;
`;

const Title = styled.p`
  font-size: 2.4rem;
  font-weight: 600;
  color: #293748;
`;

const Container = styled.div`
  background: #f8adad;
  color: #f00606;
  padding: 2rem;
  border-radius: 10px;
`;
const Logo = styled.img`
  width: 100px;
`;

const NoAuth = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');

  return (
    <Wrapper>
      <Logo src="/logo.c1c8464e.svg" alt="cognni-logo" />
      <Title>Welcome back to Cognni's Partners Portal</Title>
      <Container>
        <strong>{email}</strong> is not a Cognni admin. <br /> 1. Please use the
        button below to log out from your current user <br /> 2. Log in with a
        valid Cognni admin account
      </Container>
      <Button
        theme="page"
        style={{ width: '100%', margin: '0 auto' }}
        onClick={() =>
          window.location.replace(
            `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}/login`
          )
        }
        text="Log out"
      />
    </Wrapper>
  );
};

export default NoAuth;
