/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import { ThumbnailImageProps } from 'react-grid-gallery';
import { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete'; // import your delete icon from wherever it resides

interface ImageComponentProps extends ThumbnailImageProps {
  onDelete: (index: number) => void; // add a new prop for the delete function
}

const ImageComponentWithDelete = (props: ImageComponentProps) => {
  const [showIcon, setShowIcon] = useState(false);

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation(); // prevent the image click event from firing
    props.onDelete(props.index); // call the delete function with the image index
  };

  const imageProps = {
    ...props.imageProps,
    title: props.imageProps.title || undefined, // convert null to undefined
  };

  return (
    <div
      style={{ ...props.imageProps.style, position: 'relative' }}
      onMouseOver={() => setShowIcon(true)}
      onMouseOut={() => setShowIcon(false)}
    >
      <img {...imageProps} />

      {showIcon && (
        <DeleteIcon
          style={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }}
          onClick={handleDelete}
        />
      )}
    </div>
  );
};

export default ImageComponentWithDelete;
