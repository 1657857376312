/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import axios from 'axios';
import Select, { ActionMeta, GroupBase, MultiValue } from 'react-select';
import styled from 'styled-components';
import { config } from '../../config/config';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const Label = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 5px;
`;

const FolderPicker: React.FC<ControlProps> = (props) => {
  const { id, enabled, path, handleChange, data } = props;
  const [options, setOptions] = useState<Array<any>>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const global = useAppSelector(selectGlobal);
  const currentForm = global?.currentFormState;

  useEffect(() => {
    axios.post(config.API_MAIN + '/getFolders').then((response) => {
      const folders = response.data.data;
      const formattedFolders: any[] = folders.map(
        (folder: { full_path: string }) => ({
          value: folder.full_path,
          label: folder.full_path,
        })
      );
      setOptions(formattedFolders);
    });
  }, []);

  // Defaults
  useEffect(() => {
    if (currentForm?.folders?.length > 0) {
      setSelectedOptions(
        currentForm?.folders?.map((f: any) => ({
          value: f,
          label: f,
        }))
      );
    }
  }, [currentForm]);

  const handleSelectChange = (
    values: MultiValue<any>,
    actionMeta: ActionMeta<any>
  ) => {
    setSelectedOptions(values);
    handleChange(
      path,
      values.map((value) => value.value)
    );
  };

  return (
    <div>
      <Label>{`Folder(s)`}</Label>
      <Select
        isMulti
        id={id}
        options={options}
        value={selectedOptions}
        onChange={handleSelectChange}
        isDisabled={!enabled}
      />
      {/* <LabelContainer>
        {selectedOptions.map((option: any) => (
          <Label key={option.value}>{option.label}</Label>
        ))}
      </LabelContainer> */}
    </div>
  );
};

export default withJsonFormsControlProps(FolderPicker);
