import styled from 'styled-components';
import React from 'react';
import Dropdown from 'react-select';
import DropdownByEndpoint from './DropdownByEndpoint';
import { useState, useEffect } from 'react';
import { useDynamicQueryMutation } from '../redux/api/authSlice';

// Define your styled components
const Container = styled.div`
  display: grid;
  gap: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
`;

const StyledDropdown = styled(DropdownByEndpoint)`
  margin-bottom: 10px;
`;

const Button = styled.button`
  background-color: #008cba; /* Blue */
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  justify-self: flex-end;
`;

const Message = styled.div`
  margin-top: 20px;
  color: #333;
`;

const PopupFunctions = ({ DATA }: { DATA: any }) => {
  const [properties, setProperties] = useState({});
  const [dynamicQuery, result] = useDynamicQueryMutation();
  const [msg, setMsg] = useState('');

  const onCta = () => {
    try {
      dynamicQuery({
        endpoint: DATA.ctaEndpoint,
        method: 'POST',
        body: {
          ...properties,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setMsg('');
    if (result.status === 'fulfilled') {
      setMsg(JSON.stringify(result?.data));
    }
    if (result.status === 'rejected') {
      const anyResult: any = result;
      setMsg(JSON.stringify(anyResult?.error));
    }
  }, [result]);

  return (
    <Container>
      <Title>{DATA.title}</Title>
      {DATA.inputs.map((input: any, index: any) => (
        <React.Fragment key={index}>
          {input.type === 'dropdown' && (
            <StyledDropdown
              endpoint={input.endpoint}
              labelProperty={input.labelProperty}
              valueProperty={input.valueProperty}
              onChange={(selected: any) =>
                setProperties({ full_path: selected?.value })
              }
            />
          )}
        </React.Fragment>
      ))}
      <Button onClick={onCta}>CTA</Button>
      <Message>{msg}</Message>
    </Container>
  );
};

export default PopupFunctions;
