import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { config } from '../../config/config';

const Wrapper = styled.div``;

interface ResponseItem {
  name: string;
  url: string;
  promptId: string;
}

const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const CustomPromptId: React.FC = () => {
  const [responseData, setResponseData] = useState<ResponseItem[]>([]);

  useEffect(() => {
    const fetchDataWithAxios = async () => {
      try {
        const response = await axios.post<any>(
          config.API_MAIN + '/getCiCustomPrompt',
          {
            token: localStorage.getItem('token'),
            channelIndex: Number(localStorage.getItem('channelIndex')) || 0,
            sourceGroupIndex:
              Number(localStorage.getItem('sourceGroupIndex')) || 0,
            path: window.location.pathname,
            appId: 'partnerPortal',
            url: window.location?.host,
          }
        );
        setResponseData(response.data?.data);
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    };

    fetchDataWithAxios();
  }, []);

  return (
    <Wrapper>
      <Select>
        <option value="">Please select...</option>
        {responseData?.map((item, index) => (
          <option key={index} value={item.url} data-promptid={item.promptId}>
            {item.promptId}
          </option>
        ))}
      </Select>
    </Wrapper>
  );
};

export default CustomPromptId;
