export const exportDataToCSV = (fetchedData: any[]) => {
  // Create CSV header based on object keys
  const header = Object.keys(fetchedData[0]).join(',');

  // Create rows from the fetched data
  const rows = fetchedData.map((obj) => {
    return Object.values(obj).join(',');
  });

  // Combine header and rows
  const csvString = [header, ...rows].join('\n');

  // Create a Blob from the CSV string
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  // Create a download link
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = 'data.csv';

  // Trigger download
  link.click();

  // Release object URL
  URL.revokeObjectURL(url);
};
