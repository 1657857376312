/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectGlobal,
  setAllowDeleteAction,
  setShowDeleteAlert,
} from '../../redux/state/globalSlice';
import { ReadOnly } from './ReadOnlyStyle';
import axios from 'axios'; // Make sure to install and import axios
import { config } from '../../config/config';
import { DeleteIcon, PlusButton } from './ArrayItemControl';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DropdownIcon from './DropdownIcon';
import DeleteWarning from '../DeleteWarning';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  text-transform: capitalize;
`;

const Void = styled.div`
  display: none;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const DropdownContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

interface DropdownControlProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const DraggableDropdownContainer: React.FC<{
  index: number;
  moveItem: any;
  children: any;
}> = ({ children, index, moveItem }: any) => {
  const [, ref] = useDrag({
    type: 'ITEM',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'ITEM',
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return <div ref={(node) => ref(drop(node))}>{children}</div>;
};

const CustomReportBodyArray: React.FC<DropdownControlProps> = (props) => {
  const [options, setOptions] = useState<
    Array<{ label: string; value: string; type: any }>
  >([]);

  const [selections, setSelections] = useState<Array<string>>(['']);
  const [dynamicQuery, result] = useDynamicQueryMutation();
  const [tempIndex, setTempIndex] = useState<number>();
  const global = useAppSelector(selectGlobal);
  const dispatch = useAppDispatch();

  const promiseArray =
    props?.path === 'pages'
      ? [
          axios.post(`${config.API_MAIN}/getContentObjects?type=htmlPage`, {
            token: localStorage.getItem('token'),
            channelIndex: Number(localStorage.getItem('channelIndex')) || 0,
            sourceGroupIndex:
              Number(localStorage.getItem('sourceGroupIndex')) || 0,
            path: window.location.pathname,
            appId: 'partnerPortal',
            url: window.location?.host,
          }),
          axios.post(`${config.API_MAIN}/getContentObjects?type=pdf`, {
            token: localStorage.getItem('token'),
            channelIndex: Number(localStorage.getItem('channelIndex')) || 0,
            sourceGroupIndex:
              Number(localStorage.getItem('sourceGroupIndex')) || 0,
            path: window.location.pathname,
            appId: 'partnerPortal',
            url: window.location?.host,
          }),
          axios.post(`${config.API_MAIN}/getReportPages`, {
            token: localStorage.getItem('token'),
            channelIndex: Number(localStorage.getItem('channelIndex')) || 0,
            sourceGroupIndex:
              Number(localStorage.getItem('sourceGroupIndex')) || 0,
            path: window.location.pathname,
            appId: 'partnerPortal',
            url: window.location?.host,
          }),
        ]
      : [
          axios.post(
            `${config.API_MAIN}/getDataObjects?model=${
              (props?.path === 'body' && 'body') ||
              (props?.path === 'header' && 'header_footer') ||
              (props?.path === 'footer' && 'header_footer') ||
              (props?.path === 'sidebar' && 'sidebar')
            }`,
            {
              token: localStorage.getItem('token'),
              channelIndex: Number(localStorage.getItem('channelIndex')) || 0,
              sourceGroupIndex:
                Number(localStorage.getItem('sourceGroupIndex')) || 0,
              path: window.location.pathname,
              appId: 'partnerPortal',
              url: window.location?.host,
            }
          ),
          axios.post(
            `${config.API_MAIN}/getContentObjects?type=html&model=${
              (props?.path === 'body' && 'body') ||
              (props?.path === 'header' && 'header_footer') ||
              (props?.path === 'footer' && 'header_footer') ||
              (props?.path === 'sidebar' && 'sidebar')
            }`,
            {
              token: localStorage.getItem('token'),
              channelIndex: Number(localStorage.getItem('channelIndex')) || 0,
              sourceGroupIndex:
                Number(localStorage.getItem('sourceGroupIndex')) || 0,
              path: window.location.pathname,
              appId: 'partnerPortal',
              url: window.location?.host,
            }
          ),
        ];

  const fetchOptions = async () => {
    try {
      const [dataObjectsRes, contentObjectsRes, extraPages]: any =
        await Promise.all(promiseArray);

      // `${config.API_MAIN}/${endpointArg}?&filterId=${type}`,

      // Process and set your options here
      // Merging both responses into one array of options
      props?.path === 'pages'
        ? setOptions([
            ...dataObjectsRes?.data?.data.map((item: any) => ({
              label: item.name,
              value: item.id,
              type: item?.type,
            })),
            ...contentObjectsRes?.data?.data.map((item: any) => ({
              label: item.name,
              value: item.id,
              type: item?.type,
            })),
            ...extraPages?.data?.data.map((item: any) => ({
              label: item.name,
              value: item.id,
              type: 'Page',
            })),
          ])
        : setOptions([
            ...dataObjectsRes?.data?.data.map((item: any) => ({
              label: item.name,
              value: item.id,
              type: item?.type,
            })),
            ...contentObjectsRes?.data?.data.map((item: any) => ({
              label: item.name,
              value: item.id,
              type: item?.type,
            })),
          ]);
      // Set first option as default selection
      // if (options.length > 0) {
      //   setSelections([options[0].value]);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const removeSelection = (index: number) => {
    setTempIndex(index);
    dispatch(setShowDeleteAlert(true));
  };

  useEffect(() => {
    if (global?.allowDeleteAction) {
      setSelections((prev) => prev.filter((_, i) => i !== tempIndex));
      dispatch(setAllowDeleteAction(false));
      dispatch(setShowDeleteAlert(false));
    }
  }, [global?.allowDeleteAction]);

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (global?.rowObject[props?.path]) {
      setSelections(
        global?.rowObject[props?.path]?.map((item: any) => item?.id || item)
      );
    }
  }, [global?.rowObject]);

  const addSelection = () => {
    setSelections((prev) => [...prev, '']);
  };

  useEffect(() => {
    props.handleChange(props.path, selections);
  }, [selections]);

  const handleSelectChange =
    (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newSelections = [...selections];
      newSelections[index] = event.target.value;
      setSelections(newSelections);
      props.handleChange(props.path, newSelections);
    };

  if (
    props.path === 'sidebar' &&
    global?.currentFormState?.template === 'page_with_no_sidebar'
  )
    return <Void />;

  const moveItem = (fromIndex: any, toIndex: any): any => {
    const updatedSelections = [...selections];
    const [movedItem] = updatedSelections.splice(fromIndex, 1);
    updatedSelections.splice(toIndex, 0, movedItem);
    setSelections(updatedSelections);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Wrapper>
        <Label htmlFor={props.id}>{props?.path}</Label>
        {selections.map((selection, index) => (
          <DraggableDropdownContainer
            key={index}
            index={index}
            moveItem={moveItem}
          >
            <DropdownContainer
              key={index}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <DropdownIcon />
              <Select
                name={`${props.id}-${index}`}
                id={`${props.id}-${index}`}
                value={selection}
                onChange={handleSelectChange(index)}
              >
                <option value="">Please select...</option>{' '}
                {/* Adding a placeholder option */}
                {options.map((option, optionIndex) => (
                  <option key={optionIndex} value={option.value}>
                    {option.label} ({option.type})
                  </option>
                ))}
              </Select>
              {global?.showDeleteAlert && index === 0 && <DeleteWarning />}
              <DeleteIcon onClick={() => removeSelection(index)}>-</DeleteIcon>
            </DropdownContainer>
          </DraggableDropdownContainer>
        ))}
        {props?.path !== 'header' && props?.path !== 'footer' && (
          <PlusButton onClick={addSelection}>+</PlusButton>
        )}
      </Wrapper>
    </DndProvider>
  );
};

export default withJsonFormsControlProps(CustomReportBodyArray);
