/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useAppDispatch } from '../redux/hooks';
import { setFunctionTypeOnCondition } from '../redux/state/globalSlice';

const useAssistantFunctionCondition = (data: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Only proceed if function_type is not already set

    // Define a mapping from classification_type to function_type
    const classificationToFunctionType: any = {
      static_classification_of_single_class: 'classify',
      static_classification_of_multiple_classes: 'classify',
      dynamic_classification_of_single_class: 'classify',
      dynamic_classification_of_multiple_classes: 'classify',
      extract_text: 'extract',
      parse_text: 'parse',
      generate_text: 'generate',
      decision_text: 'decide',
      custom_text: 'custom',
    };

    // Check if data's classification_type is in the mapping
    const functionType = classificationToFunctionType[data.classification_type];

    if (functionType) {
      // Update the function_type property
      dispatch(setFunctionTypeOnCondition(functionType));
    }
  }, [data]); // Make sure to pass `data` as a dependency

  // Custom hooks return values, not JSX.
  // In this case, we could return the modified data.

  return data;
};

export default useAssistantFunctionCondition;
