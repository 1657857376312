import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { fetchData } from '../../utils/axios';
import { config } from '../../config/config';

const Wrapper = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const WarningText = styled.div`
  color: red;
`;

const ReplaceButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px 0;
`;

interface ContentControl extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const PaidSubscriptionEndsRenderer: React.FC<ContentControl> = ({
  data,
  handleChange,
  path,
  id,
}) => {
  const [stories, setStories] = useState([]);
  const [unmatchedTags, setUnmatchedTags] = useState(false);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef<any>(null);

  const getAllStories = async () => {
    setLoading(true);
    try {
      const stories = await fetchData(
        config.API_MAIN + '/public/getAllStories'
      );
      const returnedData = stories?.data?.map((st: any) => ({
        content_tag_name: st?.content_tag_name,
        mirrorText: st?.mirrorText,
        html: st?.story,
      }));
      setStories(returnedData || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllStories();
  }, []);

  const replaceTags = (content: string) => {
    let replacedContent = content;
    let unmatched = false;

    replacedContent = replacedContent?.replace(
      /\$(t|h)#(.*?)#/g,
      (match, type, tagName) => {
        const storyMatch: any = stories.find(
          (story: any) => story.content_tag_name === tagName
        );
        if (!storyMatch) {
          unmatched = true;
          return match;
        }
        return type === 'h' ? storyMatch.html : storyMatch.mirrorText;
      }
    );

    setUnmatchedTags(unmatched);
    return replacedContent;
  };

  const handleReplaceTags = () => {
    if (editorRef.current) {
      const originalContent = editorRef.current.getContent();
      const replacedContent = replaceTags(originalContent);
      editorRef.current.setContent(replacedContent);
      handleChange(path, replacedContent);
    }
  };

  if (loading) return <p>EDITOR LOADING...</p>;

  return (
    <Wrapper>
      {unmatchedTags && (
        <WarningText>Warning: Some tags were not matched!</WarningText>
      )}
      <Editor
        initialValue={data}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | blocks fontfamily fontsize backcolor | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
      <ReplaceButton onClick={handleReplaceTags}>
        Replace Content Tags
      </ReplaceButton>
    </Wrapper>
  );
};

export default withJsonFormsControlProps(PaidSubscriptionEndsRenderer);
