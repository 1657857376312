import React, { ChangeEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import { uploadImageWithFile } from '../../utils/uploadImage';
import { log } from 'util';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Image = styled.img`
  max-width: 500px;
  max-height: 300px;
  object-fit: cover;
`;

const FileInput = styled.input`
  display: none;
`;

const Button = styled.label`
  background-color: #f0f0f0;
  color: #333;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
`;

const FileName = styled.span`
  color: #666;
  font-size: 12px;
  margin-top: 5px;
`;

const LoadingText = styled.span`
  color: #999;
  font-size: 12px;
  margin-top: 5px;
`;

const DeleteIcon = styled.button`
  cursor: pointer;
  color: #6e6e6e;
  background: none;
  font-size: 2rem;
  &:hover {
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0fr;
  align-items: center;
  gap: 1rem;
`;

interface ControlProps {
  value: string;
  onChange: (event: any, index: number) => void;
}
const ImageUploadControlModified: React.FC<ControlProps> = ({
  value,
  onChange,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>(
    value ? 'Image Uploaded' : 'No image selected'
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    console.log(file);

    if (file) {
      setLoading(true);
      setFileName('Loading...');
      const imageUrl = await uploadImageAndGetUrl(file);
      console.log(imageUrl);

      // use imageUrl to update the image
      const eventModified = {
        target: {
          value: imageUrl,
        },
      };
      onChange(eventModified, 0);
      setLoading(false);
      setFileName('Image Uploaded');
    } else {
    }
  };

  return (
    <Wrapper>
      <Container>
        <Image src={value} alt="" />
        <Button htmlFor="file-input">Choose File</Button>
        {loading ? (
          <LoadingText>Loading...</LoadingText>
        ) : (
          <FileName>{fileName}</FileName>
        )}
        <FileInput
          id="file-input"
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </Container>
      {/* <DeleteIcon type="button" onClick={handleRemove}>
        -
      </DeleteIcon> */}
    </Wrapper>
  );
};

async function uploadImageAndGetUrl(file: File): Promise<string> {
  const formData = new FormData();
  formData.append('file', file);
  const img = await uploadImageWithFile(formData);
  return img;
}

export default ImageUploadControlModified;
