import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import axios from 'axios';
import { config } from '../../config/config';
import { useAppDispatch } from '../../redux/hooks';
import { setFormErrorsRedux } from '../../redux/state/globalSlice';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const FileInput = styled.input`
  margin-top: 1rem;
`;

const LoadingText = styled.p`
  color: red;
`;

interface FileUploaderProps {
  onChange: (value: string[]) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onChange }) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setFormErrorsRedux('loading...'));
    setLoading(true);
    const files = Array.from(event.target.files as FileList);
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });
    try {
      const response = await axios.post(
        config.API_MAIN + '/uploadResources',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Handle the response if needed
      onChange(response.data);
      console.log('File upload successful', response);
    } catch (error) {
      // Handle the error if needed
      console.error('Error in file upload', error);
    } finally {
      setLoading(false);
      dispatch(setFormErrorsRedux(''));
    }
  };

  return (
    <div>
      <FileInput type="file" multiple onChange={handleChange} />
      {loading && <LoadingText>Uploading...</LoadingText>}
    </div>
  );
};

interface FileControlProps extends ControlProps {
  data: string[];
  handleChange: (path: string, value: any[]) => void;
  path: string;
  id: any;
}

const CustomFilesUploader: React.FC<FileControlProps> = (props) => {
  const { data, handleChange, path } = props;
  const [files, setFiles] = useState(data || []);

  const handleFilesChange = (newFiles: string[]) => {
    setFiles(newFiles);
    handleChange(path, newFiles);
  };

  return (
    <Wrapper>
      <FileUploader onChange={handleFilesChange} />
      {/* {files.map((file, index) => (
        <img key={index} src={file} alt={`File ${index}`} />
      ))} */}
    </Wrapper>
  );
};

export default withJsonFormsControlProps(CustomFilesUploader);
