import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

const buttonTheme = {
  login: {
    padding: '1.5rem 2.4rem',
    maxWidth: '475px',
    fontSize: '22px',
    lineHeight: '26px',
    gap: '1.5rem',
    justifySelf: 'unset',
    background: '#2196f3',
    color: '#FFF',
    boxShadow: '0px 10px 20px 0px #0000001c',
  },
  page: {
    padding: '0.7rem 2.4rem',
    maxWidth: '475px',
    fontSize: '15px',
    lineHeight: '17.58px',
    gap: '1rem',
    justifySelf: 'flex-start',
    background: '#2196f3',
    color: '#FFF',
    boxShadow: '0px 10px 20px 0px #0000001c',
  },
  loading: {
    padding: '0.7rem 2.4rem',
    maxWidth: '475px',
    fontSize: '15px',
    lineHeight: '17.58px',
    gap: '1rem',
    justifySelf: 'flex-start',
    background: 'gray',
    color: '#FFF',
    boxShadow: '0px 10px 20px 0px #0000001c',
    PointerEvent: 'none',
  },
  pageCancel: {
    padding: '0.7rem 2.4rem',
    maxWidth: '475px',
    fontSize: '15px',
    lineHeight: '17.58px',
    gap: '1rem',
    justifySelf: 'flex-start',
    background: 'transparent',
    color: 'rgba(41, 55, 72, 0.85)',
    boxShadow: 'unset',
  },
  danger: {
    padding: '0.7rem 2.4rem',
    maxWidth: '475px',
    fontSize: '15px',
    lineHeight: '17.58px',
    gap: '1rem',
    justifySelf: 'flex-start',
    background: '#f35221',
    color: '#FFF',
    boxShadow: '0px 10px 20px 0px #0000001c',
  },
};

const ButtonWrapper = styled.button`
  display: grid;
  grid-auto-flow: column;
  justify-items: flex-start;
  justify-self: ${(props) => props.theme.justifySelf};
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.gap};
  background: ${(props) => props.theme.background};
  box-shadow: ${(props) => props.theme.boxShadow};
  max-width: ${(props) => props.theme.maxWidth};
  padding: ${(props) => props.theme.padding};
  border-radius: 50px;
  pointer-events: ${(props) => props.theme?.PointerEvent || 'unset'};
  cursor: pointer;
`;
const Text = styled.p`
  color: ${(props) => props.theme.color};
  //styleName: heading 3 bold;
  font-family: Roboto;
  font-size: ${(props) => props.theme.fontSize};
  font-weight: 700;
  line-height: ${(props) => props.theme.lineHeight};
  letter-spacing: 0.01em;
  text-align: center;
`;

const Icon = styled.img``;

interface IButton {
  text: string;
  link?: string;
  icon?: string;
  onClick?: () => void;
  style?: any;
  theme: 'login' | 'page' | 'pageCancel' | 'loading' | 'danger';
}

const Button: React.FC<IButton> = ({
  text,
  link,
  icon,
  style,
  onClick = () => {},
  theme = 'login',
}) => {
  return (
    <ThemeProvider theme={buttonTheme[theme]}>
      <ButtonWrapper style={style} onClick={onClick}>
        {icon && <Icon src={icon} alt="icon" />}
        {text && <Text>{text}</Text>}
      </ButtonWrapper>
    </ThemeProvider>
  );
};

export default Button;
