/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';

// Define your styled components
const InputWrapper = styled.div`
  margin: 1rem 0;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 2rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
`;

const NoSpaceInput = (props: ControlProps) => {
  const { id, enabled, uischema, path, handleChange, data } = props;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replaceAll(' ', '_')?.toLowerCase();
    handleChange(path, value);
  };

  return (
    <InputWrapper>
      <StyledLabel htmlFor={id}>{props.label}</StyledLabel>
      <StyledInput
        id={id}
        type="text"
        value={data || ''}
        onChange={onChange}
        disabled={!enabled}
      />
    </InputWrapper>
  );
};

export default withJsonFormsControlProps(NoSpaceInput);
