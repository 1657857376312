import React, { useEffect, useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import Notification from './Notification';
import axios from 'axios';
import ImageComponentWithDelete from './ImageComponentWithDelete';
import { config } from '../config/config';
import Select from 'react-select';
import styled from 'styled-components';
import { FadeInDiv } from '../style/Animations';

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  height: 38px;
`;

const SearchContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 5rem;
`;

interface ImageProps {
  data: any;
}

const ImageGridGallery: React.FC<ImageProps> = ({ data }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [imageData, setImageData] = useState<any>(data?.data || []);
  const [filteredImages, setFilteredImages] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedFolders, setSelectedFolders] = useState<Set<string>>(
    new Set()
  );
  const [folderOptions, setFolderOptions] = useState<any>([]);

  const handleClickImage: any = (
    index: number,
    event: React.MouseEvent<Element, MouseEvent>
  ) => {
    navigator.clipboard.writeText(filteredImages?.[index]?.url);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 2000);
  };

  const handleDeleteImage = async (index: number) => {
    const image = filteredImages[index];
    try {
      const response = await axios.post(
        config.API_MAIN + '/deleteUploadedResource',
        {
          objectId: image._id,
        }
      );
      if (response.status === 200) {
        setImageData(imageData.filter((item: any) => item._id !== image._id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setImageData(data?.data);
  }, [data]);

  useEffect(() => {
    let folderSet = new Set<string>();
    imageData?.forEach((img: any) => {
      img?.folders?.forEach((folder: string) => {
        folderSet?.add(folder);
      });
    });
    setFolderOptions(
      Array.from(folderSet)?.map((folder) => ({ value: folder, label: folder }))
    );
  }, [imageData]);

  useEffect(() => {
    setFilteredImages(
      imageData?.filter((image: any) =>
        selectedFolders.size > 0
          ? image.folders.some((folder: string) => selectedFolders.has(folder))
          : true
      )
    );
  }, [selectedFolders, imageData]);

  useEffect(() => {
    if (searchTerm) {
      setFilteredImages(
        filteredImages.filter((image: any) =>
          image.folders.some((folder: string) =>
            folder.toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      );
    } else {
      setFilteredImages(imageData);
    }
  }, [searchTerm, imageData]);

  return (
    <FadeInDiv>
      <SearchContainer>
        <Select
          isMulti
          options={folderOptions}
          onChange={(selectedOptions: any) => {
            setSelectedFolders(
              new Set(selectedOptions.map((opt: any) => opt.value))
            );
          }}
        />
        <StyledInput
          type="text"
          placeholder="Search by folder name"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchContainer>
      <Gallery
        images={filteredImages?.map((ctx: any) => ({
          src: ctx?.url,
          thumbnailCaption: ctx?.name,
        }))}
        onClick={handleClickImage}
        thumbnailImageComponent={(props) => (
          <ImageComponentWithDelete {...props} onDelete={handleDeleteImage} />
        )}
      />
      <Notification show={showNotification}>Copied to clipboard</Notification>
    </FadeInDiv>
  );
};

export default ImageGridGallery;
