import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const DatePicker = styled.input.attrs({ type: 'date' })`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface DateControlProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const PaidSubscriptionEndsRenderer: React.FC<DateControlProps> = ({
  data,
  handleChange,
  path,
  id,
}) => {
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(path, event.target.value);
  };

  return (
    <div>
      <Label htmlFor={id}>Won Date</Label>
      <DatePicker
        id={id}
        value={data?.split('T')[0]}
        onChange={handleDateChange}
      />
    </div>
  );
};

export default withJsonFormsControlProps(PaidSubscriptionEndsRenderer);
