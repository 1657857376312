/* eslint-disable styled-components-a11y/click-events-have-key-events */
/* eslint-disable styled-components-a11y/no-static-element-interactions */
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import Dropdown from '../Dropdown';
import { useParams } from 'react-router';
import { getPastQuarters } from '../../utils/giveQuartersDate';

const Wrapper: any = styled.div`
  display: grid;
  place-items: center;
  align-content: center;
  position: ${({ positionRelative }: any) =>
    positionRelative ? 'unset' : 'relative'};
  width: 208px;
  height: 208px;
  background: rgba(73, 85, 100, 0.7);
  border-radius: 50%;
  gap: 1.4rem;
`;

const Group: any = styled.div`
  display: grid;
  place-items: center;
  cursor: pointer;
  * {
    color: ${({ active }: any) =>
      active ? 'rgba(162, 237, 135, 1) !important' : 'unset'};
  }
`;

const Number = styled.p`
  font-family: Roboto;
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #ffffff;
`;
const Label = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
`;

const ParamTitle = styled.p`
  position: absolute;
  top: 15px;
  background: #fff;
  padding: 1px 10px;
  color: #333;
  font-size: 1.3rem;
  font-weight: 700;
`;

const ParamsBox = styled.div`
  position: relative;
`;

const CloseIcon = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
  right: 2px;
  top: -7px;
  cursor: pointer;
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 14px;
    width: 2px;
    background-color: #333333a8;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

interface ICircle {
  totalOrg: number;
  totalUsers: number;
  setCircleMode: React.Dispatch<React.SetStateAction<number>>;
  funnel: any;
  circleMode: number;
  stats: any;
}

const Circle: React.FC<ICircle> = ({
  totalOrg,
  totalUsers,
  setCircleMode,
  funnel,
  circleMode,
  stats,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const navigate = useNavigate();
  const params = useParams();
  // onClick={() => navigate('/management/customers-overview')}
  const items = [
    { name: 'Qualified Opportunities', value: '1', type: 'total' },
    {
      name: 'Conversion',
      value: '2',
      type: 'conversion',
      formulaProperty: 'won',
    },
  ];

  const selectedItemIndex = items[selectedIndex];
  const foundStatByFormula = stats?.find(
    (stat: any) => stat?.stage === selectedItemIndex?.formulaProperty
  );

  const navigateToAll = () => {
    let url = String(window.location);
    let parts = url.split('/');
    parts[parts.length - 1] = 'all';
    url = parts.join('/');
    window.location.replace(url);
  };

  return (
    <Wrapper positionRelative={funnel?.enhancedCircle}>
      {funnel?.useTimeTitle && params?.quarter !== 'all' && (
        <ParamTitle>
          <ParamsBox>
            <CloseIcon onClick={navigateToAll} />
            {params?.quarter}
          </ParamsBox>
        </ParamTitle>
      )}
      {funnel?.enhancedCircle && (
        <Dropdown
          setSelectedIndex={setSelectedIndex}
          title={selectedItemIndex?.name}
          items={funnel?.enhancedCircle}
        />
      )}
      <Group active={circleMode === 1} onClick={() => setCircleMode(1)}>
        <Number>
          {selectedItemIndex?.formulaProperty && totalOrg
            ? foundStatByFormula
              ? ((foundStatByFormula?.totalOrgs / totalOrg) * 100).toFixed(
                  1.1
                ) + '%'
              : 0 + '%'
            : totalOrg?.toLocaleString()}
        </Number>
        <Label>{funnel?.countLabel}</Label>
      </Group>
      <Group active={circleMode === 0} onClick={() => setCircleMode(0)}>
        <Number>
          {selectedItemIndex?.formulaProperty && totalUsers
            ? foundStatByFormula
              ? ((foundStatByFormula?.totalUsers / totalUsers) * 100).toFixed(
                  1.1
                ) + '%'
              : 0 + '%'
            : totalUsers?.toLocaleString()}
        </Number>
        {/* <Label>{funnel?.sumLabel}</Label> */}
        <Label>Active Subscriptions</Label>
      </Group>
    </Wrapper>
  );
};

export default Circle;
