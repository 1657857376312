/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectGlobal,
  setCurrentFormState,
} from '../../redux/state/globalSlice';

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const Selector = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface SelectControlProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const PageTypeRenderer: React.FC<SelectControlProps> = ({
  data,
  handleChange,
  path,
  id,
}) => {
  const dispatch = useAppDispatch();
  const global = useAppSelector(selectGlobal);

  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    dispatch(
      setCurrentFormState({ ...global?.currentFormState, template: data })
    );
    handleChange(path, event.target.value);
  };

  return (
    <div>
      <Label htmlFor={id}>Page Type</Label>

      <Selector id={id} value={data} onChange={handleSelectionChange}>
        <option value="">Select an option</option>
        <option value="page_with_left_sidebar">Page With Left Sidebar</option>
        <option value="page_with_right_sidebar">Page With Right Sidebar</option>
        <option value="page_with_no_sidebar">Page With No Sidebar</option>
      </Selector>
    </div>
  );
};

export default withJsonFormsControlProps(PageTypeRenderer);
