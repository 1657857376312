import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

type ExtraInfoType = {};

function withState<P>(Component: React.ComponentType<P & ExtraInfoType>) {
  const ComponentWithExtraInfo = (props: P) => {
    const globalState = useAppSelector(selectGlobal);
    const dispatch = useAppDispatch();
    // At this point, the props being passed in are the original props the component expects.
    return (
      <Component {...props} globalState={globalState} dispatch={dispatch} />
    );
  };

  return ComponentWithExtraInfo;
}

export default withState;
