/* eslint-disable styled-components-a11y/no-onchange */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { config } from '../../config/config';

const Wrapper = styled.div`
  select {
    width: 100%;
    max-width: 500px;
  }
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  option {
    max-width: 500px;
  }
`;

interface ImageUploadControlProps {
  data: any;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

interface ResponseItem {
  name: string;
  url: string;
}

const CustomSourcePick: React.FC<ImageUploadControlProps> = ({
  data, // assuming this is the data you want to POST
  handleChange,
  path,
  id,
}) => {
  const [responseData, setResponseData] = useState<any>([]);

  useEffect(() => {
    const fetchDataWithAxios = async () => {
      try {
        // Replace 'your-endpoint-url' with your actual endpoint URL
        const response = await axios.post<any>(
          config.API_MAIN + '/getResourceGroup?type=pdf',
          data
        );
        setResponseData(response.data?.data);
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    };

    fetchDataWithAxios();
  }, [data]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(path, event.target.value);
  };

  return (
    <Wrapper>
      <Select value={data} onChange={handleOptionChange}>
        <option value="">Please select...</option>
        {responseData?.map((item: any, index: number) => (
          <option key={index} value={item.url}>
            {item.name}
          </option>
        ))}
      </Select>
    </Wrapper>
  );
};

export default withJsonFormsControlProps(CustomSourcePick);
