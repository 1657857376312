import React from 'react';
import axios from 'axios';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  justify-self: flex-start;
  margin-top: 0px;
  margin-left: 240px;
`;

const ActionButton = styled.button`
  background-color: #555; /* dark grey background */
  color: #ddd; /* lighter grey text */
  border: none;
  padding: 12px 24px; /* vertical and horizontal padding */
  margin: 5px;
  font-size: 10px;
  border-radius: 4px; /* rounded corners */
  cursor: pointer; /* indicates clickable */
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  /* On hover, brighten background */
  &:hover {
    background-color: #666;
    transform: scale(1.05); /* Slightly scale the size */
  }

  /* On click, darken background */
  &:active {
    background-color: #444;
  }

  /* Add some features for disabled state */
  &:disabled {
    background-color: #333;
    cursor: not-allowed;
  }
`;

interface Action {
  value: string;
  icon: string;
  buttonName: string;
  endpoint: string;
  method: string;
  permissions: string[];
}

interface Menu {
  id: number;
  permissions: string[];
  actions: Action[];
}

const ActionsMenu = ({
  actionMenu,
  setPopupFunctionsData,
}: {
  actionMenu?: any;
  setPopupFunctionsData?: any;
}) => {
  const { authenticated } = useAppSelector(selectGlobal);
  const userRole = authenticated?.user?.userRole?.toLowerCase();
  const actionsMenu =
    actionMenu?.actions ||
    (authenticated?.user?.pages?.find((p: any) => p?.pageSettings)?.actionsMenu
      ?.actions as Action[]) ||
    [];
  const menuPermission =
    actionMenu?.permissions ||
    authenticated?.user?.pages?.find((p: any) => p?.pageSettings)?.actionsMenu
      ?.permissions ||
    [];

  const makeRequest = (endpoint: string, method: string) => {
    axios({
      method,
      url: endpoint,
    })
      .then((response) => {
        console.log('Success:', response);
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  };

  const actionButtons = actionsMenu.map((action: any) => (
    <ActionButton
      key={action.value}
      onClick={() => {
        // makeRequest(action.endpoint, action.method);
        setPopupFunctionsData(action?.functions);
      }}
    >
      {action.buttonName}
    </ActionButton>
  ));

  return (
    <Wrapper>
      {menuPermission?.includes(userRole?.toLowerCase()) &&
      actionButtons.length > 0
        ? actionButtons
        : ''}
    </Wrapper>
  );
};

export default ActionsMenu;
