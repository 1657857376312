/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
`;
const HTMLContainer = styled.div`
  max-width: 600px;
  img {
    width: 100%;
  }
`;

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const Title = styled.h2`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1.6rem;
  margin: 1rem 0;
`;

interface HtmlEditorProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const CustomHTMLObjectPreview: React.FC<HtmlEditorProps> = (props) => {
  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [dynamicQuery, result] = useDynamicQueryMutation();
  const global = useAppSelector(selectGlobal);

  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: '/partner_list',
        method: 'POST',
        body: {
          foundObject: global?.rowObject,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      setOptions(result?.data?.data);
    }
  }, [result]);

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        props.handleChange(props.path, e.target?.result as string);
      };

      reader.readAsText(file);
    }
  };

  return (
    <Wrapper>
      {/* <Label htmlFor={props.id}>HTML Editor</Label> */}
      <div>
        <Title>Preview</Title>
        <HTMLContainer
          dangerouslySetInnerHTML={{
            __html: global?.currentHTML || global?.rowObject?.source,
          }}
        />
      </div>

      {/* <HTMLContainer>
        <Title>Editor</Title>
        <CodeMirror
          options={{
            lineNumbers: true,
            mode: 'xml',
          }}
          value={props.data || global?.rowObject?.source}
          onChange={(editor, data, value) => {
            props.handleChange(props.path, value);
          }}
        />
      </HTMLContainer>

      <input
        type="file"
        accept=".html"
        placeholder="Upload a HTML file"
        onChange={handleUpload}
      /> */}
    </Wrapper>
  );
};

export default withJsonFormsControlProps(CustomHTMLObjectPreview);
