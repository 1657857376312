import React, { useEffect, useState } from 'react';
import { PlusButton } from '../ArrayItemControl';
import { ArrayItems, DeleteIcon, InputField } from '../contentTypeRendererv2';

interface Iprops {
  handleFilledData: any;
  propertyName: string;
  defaultValue: any;
}

const ArrayOfTextControl: React.FC<Iprops> = ({
  handleFilledData,
  propertyName,
  defaultValue,
}) => {
  const [count, setCount] = useState(1);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    handleFilledData(propertyName, data, true);
  }, [data]);

  const dataHandler = (index: number, value: any) => {
    const newData = [...data];
    newData[index] = value;
    setData(newData);
  };
  useEffect(() => {
    if (defaultValue || defaultValue?.length) {
      // Default Value
      setCount(defaultValue?.length || 1);
      setData(defaultValue);
    }
  }, []);

  return (
    <ArrayItems>
      {new Array(count)?.fill(null).map((t: any, index: number) => (
        <InputField
          value={data?.[index]}
          key={index}
          onChange={(e) => dataHandler(index, e?.target?.value)}
          placeholder={propertyName}
        />
      ))}
      <PlusButton onClick={() => setCount(count + 1)}>+</PlusButton>
      <DeleteIcon
        onClick={() => {
          if (count > 1) {
            setCount(count - 1);
            const newData = [...data];
            newData.pop();
            setData(newData);
          }
        }}
      >
        -
      </DeleteIcon>
    </ArrayItems>
  );
};

export default ArrayOfTextControl;
