import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { postData } from '../../../utils/axios';
import { DeleteIcon, PlusButton } from '../ArrayItemControl';

const PreviewSelected = styled.img`
  width: 100%;
  max-width: 100px;
  object-fit: cover;
`;

const ArrayItems = styled.div`
  display: grid;
  gap: 10px;
`;

const Option = styled.div``;

interface Iprops {
  handleFilledData: any;
  propertyName: string;
  defaultValue: any;
  single: boolean;
}

const ArrayOfMediaControl: React.FC<Iprops> = ({
  handleFilledData,
  propertyName,
  defaultValue,
  single,
}) => {
  const [count, setCount] = useState(1);
  const [data, setData] = useState<any>([]);
  const [sources, setSources] = useState<any>([]);

  useEffect(() => {
    handleFilledData(propertyName, data, true);
  }, [data]);

  const dataHandler = (index: number, value: any) => {
    if (!single) {
      const newData = [...data];
      newData[index] = value;
      return setData(newData);
    }
    setData(value);
  };

  useEffect(() => {
    if (defaultValue?.length || defaultValue) {
      setCount(single ? 1 : defaultValue?.length || 1);
      setData(defaultValue);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await postData('/getResourceGroup', {});
        setSources(result?.data);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);

  const options = sources.map((option: any) => ({
    value: option.url,
    label: (
      <Option>
        <img src={option.url} alt={option.name} width="50" />
        {option.name}
      </Option>
    ),
  }));

  return (
    <ArrayItems>
      {new Array(count).fill(null).map((_, index) => (
        <ArrayItems key={index}>
          <Select
            options={options}
            onChange={(selectedOption) => {
              dataHandler(index, selectedOption ? selectedOption.value : '');
            }}
            value={options.find(
              (option: any) => option.value === (single ? data : data[index])
            )}
          />
        </ArrayItems>
      ))}
      {!single && (
        <>
          <PlusButton onClick={() => setCount(count + 1)}>+</PlusButton>
          <DeleteIcon
            onClick={() => {
              if (count > 1) {
                setCount(count - 1);
                const newData = [...data];
                newData.pop();
                setData(newData);
              }
            }}
          >
            -
          </DeleteIcon>
        </>
      )}
    </ArrayItems>
  );
};

export default ArrayOfMediaControl;
