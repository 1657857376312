// api.ts
import axios, { AxiosResponse } from 'axios';
import { config } from '../config/config';

const axiosInstance = axios.create({
  baseURL: config.API_MAIN,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchData = async (endpoint: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postData = async (
  endpoint: string,
  data: object
): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const putData = async (endpoint: string, data: object): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.put(endpoint, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteData = async (endpoint: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};
