/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { config } from '../config/config';

const ImportCVS = ({ cvsEndpoint }: { cvsEndpoint: string }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      // Use PapaParse to read and parse the file
      Papa.parse(file, {
        complete: async (results) => {
          try {
            const response = await axios.post(config.API_MAIN + cvsEndpoint, {
              data: results.data,
              token: localStorage.getItem('token'),
              channelIndex: Number(localStorage.getItem('channelIndex')) || 0,
              sourceGroupIndex:
                Number(localStorage.getItem('sourceGroupIndex')) || 0,
              path: window.location.pathname,
              appId: 'partnerPortal',
              url: window.location?.host,
            });

            console.log('Server Response:', response.data);
            window.location.reload();
          } catch (error) {
            console.error('Error uploading data:', error);
          }
        },
        header: true,
      });
    }
  };

  const handleClick = () => {
    // Programmatically click the hidden file input element when the text is clicked
    fileInputRef.current?.click();
  };

  return (
    <div>
      <p onClick={handleClick} style={{ cursor: 'pointer' }}>
        Import CVS
      </p>
      <input
        type="file"
        name="csv"
        accept=".csv"
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the input element
        onChange={handleFileChange} // Automatically upload when file is selected
      />
    </div>
  );
};

export default ImportCVS;
