import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const Notification = styled.div<{ show: boolean }>`
  position: fixed;
  bottom: 1em;
  right: 1em;
  padding: 1em;
  background: #00c4a7;
  color: white;
  border-radius: 5px;
  transition: all 0.3s ease;
  animation: ${({ show }) => (show ? slideIn : slideOut)} 0.5s forwards;
`;

export default Notification;
