/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import { ReadOnly } from './ReadOnlyStyle';

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface DropdownControlProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const CustomPartnerRenderer: React.FC<DropdownControlProps> = (props) => {
  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [dynamicQuery, result] = useDynamicQueryMutation();
  const global = useAppSelector(selectGlobal);

  // Assuming fetchOptions is a function that returns a Promise that resolves to the array of options
  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: '/partner_list',
        method: 'POST',
        body: {
          foundObject: global?.rowObject,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      setOptions(result?.data?.data);
    }
  }, [result]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.handleChange(props.path, event.target.value);
  };

  return (
    <ReadOnly readOnly={props?.uischema?.options?.readOnly}>
      <Label htmlFor={props.id}>Partner</Label>
      <Select id={props.id} value={props.data} onChange={handleSelectChange}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </ReadOnly>
  );
};

export default withJsonFormsControlProps(CustomPartnerRenderer);
