import axios from 'axios';
import { config } from '../config/config';

const uploadImageWithFile = async (formData: any) => {
  try {
    const post = await axios.post(`${config.API_MAIN}/image/upload`, formData);
    return post.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { uploadImageWithFile };
