/* eslint-disable styled-components-a11y/no-static-element-interactions */
/* eslint-disable styled-components-a11y/no-noninteractive-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import styled from 'styled-components';

interface DropdownProps {
  title: string;
  items: Array<{ name: string; value: string }>;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

interface ListItemProps {
  onClick: () => void;
}

const DropdownContainer = styled.div`
  width: 160px;
  position: absolute;
  top: 42px;
  text-align: center;
  font-size: 1.2rem;
`;

const DropdownHeader = styled.div`
  padding: 3px 10px;
  color: #fff;
  background-color: #bebdbd;
  border-radius: 50px;
  cursor: pointer;
`;

const DropdownListContainer = styled.div`
  position: absolute;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  z-index: 2;
  margin-top: 0.5rem;
  font-size: 1.2rem;
`;

const DropdownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;

const ListItem = styled.li<ListItemProps>`
  list-style: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f8f9fa;
  }
`;

const Dropdown: React.FC<DropdownProps> = ({
  title,
  items = [],
  setSelectedIndex,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleItemClick = (
    item: { name: string; value: string },
    index: number
  ) => {
    setIsOpen(false);
    setSelectedIndex(index);
  };

  return (
    <DropdownContainer>
      <DropdownHeader onClick={toggleOpen}>{title}</DropdownHeader>
      {isOpen && (
        <DropdownListContainer>
          <DropdownList>
            {items.map((item, index) => (
              <ListItem
                key={index}
                onClick={() => handleItemClick(item, index)}
              >
                {item.name}
              </ListItem>
            ))}
          </DropdownList>
        </DropdownListContainer>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
