import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10px;
  cursor: grab;
  div {
    background: #333;
    width: 15px;
    height: 1px;
  }
`;

const DropdownIcon = () => {
  return (
    <Wrapper>
      <div></div>
      <div></div>
      <div></div>
    </Wrapper>
  );
};

export default DropdownIcon;
