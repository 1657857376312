/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import { ReadOnly } from './ReadOnlyStyle';

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface DropdownControlProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const CustomLocationFolder: React.FC<DropdownControlProps> = (props) => {
  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [defValue, setDefValue] = useState<string | undefined>('');
  const [dynamicQuery, result] = useDynamicQueryMutation();
  const global = useAppSelector(selectGlobal);
  const TYPE = window.location.pathname?.includes('story') && 'category';

  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: `/au/getFolders`,
        method: 'POST',
        body: {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      const newOptions = result?.data?.data?.map((item: any) => ({
        label: item?.full_path,
        value: item?.full_path,
      }));
      setOptions(newOptions || []);
    }
  }, [result]);

  useEffect(() => {
    if (defValue) {
      props.handleChange(props.path, defValue);
    }
  }, [defValue]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDefValue(event.target.value);
    props.handleChange(props.path, event.target.value);
  };

  return (
    <ReadOnly readOnly={props?.uischema?.options?.readOnly}>
      <Label htmlFor={props.id}>Location</Label>
      <Select id={props.id} value={defValue} onChange={handleSelectChange}>
        <option value="" disabled></option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </ReadOnly>
  );
};

export default withJsonFormsControlProps(CustomLocationFolder);
