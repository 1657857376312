import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';

interface Option {
  label: string;
  value: string;
}

const Label = styled.p`
  margin-bottom: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: #1d1d1d;
  ::first-letter {
    text-transform: uppercase;
  }
`;

const CmsContentPicker: React.FC<any> = ({
  data,
  handleChange: dataHandler,
  path,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[][]>([]);
  const [numOptions, setNumOptions] = useState(data?.length || 1);

  const [dynamicQuery, result] = useDynamicQueryMutation();

  // Assuming fetchOptions is a function that returns a Promise that resolves to the array of options
  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: '/getContents',
        method: 'POST',
        body: {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    generateOutput();
  }, [selectedOptions, numOptions]);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      setOptions(
        result?.data?.data?.map((item: any) => ({
          label: item?.name,
          value: item?._id,
        }))
      );
    }
  }, [result]);

  const handleChange = (option: Option[] | any, index: number) => {
    let newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = option || [];
    setSelectedOptions(newSelectedOptions);
  };

  const handleNumOptionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newNumOptions = parseInt(event.target.value);
    newNumOptions = newNumOptions >= 1 ? newNumOptions : 1;
    setNumOptions(newNumOptions);

    if (newNumOptions > selectedOptions.length) {
      setSelectedOptions([
        ...selectedOptions,
        ...Array(newNumOptions - selectedOptions.length).fill([]),
      ]);
    } else {
      setSelectedOptions(selectedOptions.slice(0, newNumOptions));
    }
  };

  const generateOutput = () => {
    let outputObject = {
      options: numOptions,
      objects: selectedOptions.map((curr, index) =>
        curr.map((option) => option.value)
      ),
    };
    dataHandler(path, { ...outputObject });
    dataHandler(
      path,
      selectedOptions.map((curr, index) => curr.map((option) => option.value))
    );
  };
  //   console.log(
  //     data?.[0]?.map((item: any) => ({
  //       label: item?.name,
  //       value: item?._id,
  //     }))
  //   );

  return (
    <div>
      <Label>Number of options:</Label>
      <input
        type="number"
        min="1"
        value={data?.length || 1}
        onChange={handleNumOptionsChange}
      />
      {Array.from(Array(numOptions)).map((_, index) => (
        <div key={index}>
          <Label>Content {index + 1}</Label>
          <Select
            isMulti
            options={options}
            onChange={(selectedOption) => handleChange(selectedOption, index)}
            defaultValue={
              data?.[index]?.map((item: any) => ({
                label: item?.name,
                value: item?._id,
              })) || []
            }
          />
        </div>
      ))}
    </div>
  );
};

export default withJsonFormsControlProps(CmsContentPicker);
