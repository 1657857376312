import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import axios from 'axios';
import { useParams } from 'react-router';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { config } from '../../config/config';
const Wrapper = styled.div`
  margin: 1rem 0;
`;
const Label: any = styled.p`
  margin-bottom: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: ${({ bold }: any) => (bold ? 600 : 400)};
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: #1d1d1d;
  ::first-letter {
    text-transform: uppercase;
  }
`;
const endpointArg = 'getResources';
const typeArg = 'resources';
const dataArg = 'resources';

const ResourcesAutomatedPick: React.FC<any> = ({
  data,
  handleChange,
  path,
  popupData,
}) => {
  const params = useParams<any>();

  const [dynamicQuery, tabResult] = useDynamicQueryMutation();
  const [dynamicQueryOptions, optionsResult] = useDynamicQueryMutation();
  const [tabs, setTabs] = useState<any[]>([]);
  const [lastType, setLastType] = useState<string>('');
  const [options, setStoryOptions] = useState<any[]>([]);
  const [picks, setPicks] = useState<any>({ name: typeArg });

  const urlPath = window.location?.pathname;
  let typeBy =
    (urlPath?.includes('resource') && 'resource') ||
    (urlPath?.includes('story') && 'story') ||
    (urlPath?.includes('number') && 'number');

  const checkTabs = async () => {
    try {
      dynamicQuery({
        endpoint: `/list-types?listType=${params.id}&type=${typeBy}`,
        method: 'POST',
        body: {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getOptions = async (type: string) => {
    try {
      // axios handle
      const result = await axios.post(
        `${config.API_MAIN}/${endpointArg}?&filterId=${type}`,
        {
          token: localStorage.getItem('token'),
          channelIndex: Number(localStorage.getItem('channelIndex')) || 0,
          sourceGroupIndex:
            Number(localStorage.getItem('sourceGroupIndex')) || 0,
          path: window.location.pathname,
          appId: 'partnerPortal',
          url: window.location?.host,
        }
      );
      setStoryOptions((prev) => [
        ...prev,
        {
          label: type,
          options: result?.data?.data?.map((item: any) => ({
            label: item?.title,
            value: item?._id,
          })),
        },
      ]);
      console.log(result?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = (label: string, selectedOptions: any) => {
    const newPicks = { ...picks };
    const existingLabel = newPicks.types?.find(
      (lbl: any) => lbl.name === label
    );

    if (existingLabel) {
      existingLabel.data = selectedOptions; // Replace existing data
    } else {
      // Add new label
      if (!newPicks.types) newPicks.types = [];
      newPicks.types.push({
        name: label,
        data: selectedOptions,
      });
    }
    setPicks(newPicks);
    handleChange(path, newPicks);
  };

  console.log(data);

  useEffect(() => {
    checkTabs();
  }, []);

  useEffect(() => {
    if (tabs.length > 0) {
      tabs[0]?.map((item: any, index: number) => {
        getOptions(item);
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (tabResult.status === 'fulfilled') {
      // do something
      setTabs(
        tabResult?.data?.data
          ?.map((item: any) => item?.tabs)
          ?.map((item: any) => item?.[dataArg])
      );
    }
  }, [tabResult]);

  return (
    <Wrapper>
      <Label bold={true}>{typeArg}</Label>
      {tabs[0]?.map((tab: any, index: number) => (
        <div key={index}>
          <Label>{tab?.replaceAll('_', ' ')}</Label>
          <Select
            isMulti
            options={options?.find((item: any) => item?.label === tab)?.options}
            onChange={(selectedOption) => handleSave(tab, selectedOption)}
            // defaultValue={
            //   data[index]?.map((item: any) => ({
            //     label: item?.name,
            //     value: item?._id,
            //   })) || []
            // }
          />
        </div>
      ))}
    </Wrapper>
  );
};

export default withJsonFormsControlProps(ResourcesAutomatedPick);
