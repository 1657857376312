/* eslint-disable styled-components-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { prev } from 'cheerio/lib/api/traversing';
import ArrayOfTextControl from './controlTypeItems/ArrayOfTextControl';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import ArrayOfMediaControl from './controlTypeItems/ArrayOfMediaControl';
import ArrayOfTextLongTextMediaControl from './controlTypeItems/arrayOfTextLongTextMediaControl';
import ArrayOfTextMediaControl from './controlTypeItems/arrayOfTextMediaControl';
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0.5rem;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const PreviewImage = styled.img`
  width: 100%;
  max-width: 500px;
  object-fit: cover;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 38px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const DeleteIcon = styled.button`
  cursor: pointer;
  color: #6e6e6e;
  background: none;
  font-size: 2rem;
  &:hover {
    opacity: 0.7;
  }
`;
const PlusButton = styled.button`
  justify-self: flex-end;
  background: none;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  background: #333;
  &:hover {
    opacity: 0.7;
  }
`;

export const ArrayItems = styled.div`
  border: solid #333 1px;
  padding: 10px;
  border-radius: 10px;
  display: grid;
  gap: 10px;
`;

const TextTextWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

interface ImageUploadControlProps {
  data: string;
  handleChange: any;
  path: string;
  id: any;
  label: any;
}

const ContentTypeRendererv2: React.FC<ImageUploadControlProps> = ({
  data,
  handleChange,
  path,
  id,
  label,
}) => {
  const [pickedTypeId, setPickedTypeId] = useState<any>(null);
  const [pickedType, setPickedType] = useState<any>(null);
  const [fullData, setFullData] = useState<any>([]);
  const [filledData, setFilledData] = useState<any>({});
  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [dynamicQuery, result] = useDynamicQueryMutation();

  const { currentFormState } = useAppSelector(selectGlobal);

  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: '/getElementTypes',
        method: 'POST',
        body: {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  // Handle Default
  useEffect(() => {
    setPickedTypeId(currentFormState?.elementType?._id);
    setFilledData(currentFormState?.content);
  }, []);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      setFullData(result.data.data);
      setOptions(
        result.data.data.map((item: any) => ({
          label: item.name,
          value: item._id,
        }))
      );
    }
  }, [result]);

  const setPickedTypeFromData = useCallback(() => {
    const foundType = fullData?.find((d: any) => d?._id === pickedTypeId);
    setPickedType(foundType);
  }, [fullData, pickedTypeId]);

  useEffect(() => {
    setPickedTypeFromData();
  }, [fullData, pickedTypeId, setPickedTypeFromData]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setPickedTypeId(selectedValue);
  };

  const handleFilledData = (
    propertyName: string,
    value: any,
    multi: boolean
  ) => {
    setFilledData((prev: any) => ({ ...prev, [propertyName]: value }));
  };

  useEffect(() => {
    handleChange(path, {
      data: filledData,
      pickedTypeId,
    });
  }, [filledData]);

  const ComponentRender = ({
    dataType,
    propertyName,
  }: {
    dataType: string;
    propertyName: string;
  }) => {
    switch (dataType) {
      case 'text':
        return (
          <InputField
            value={filledData?.[propertyName]}
            onChange={(e) =>
              handleFilledData(propertyName, e?.target?.value, false)
            }
            placeholder={propertyName}
          />
        );
      case 'longText':
        return (
          <Textarea
            value={filledData?.[propertyName]}
            onChange={(e) =>
              handleFilledData(propertyName, e?.target?.value, false)
            }
            placeholder={propertyName}
          />
        );
      case 'arrayOfTextControl':
        return (
          <ArrayOfTextControl
            defaultValue={filledData?.[propertyName]}
            handleFilledData={handleFilledData}
            propertyName={propertyName}
          />
        );
      case 'arrayOfMediaControl':
        return (
          <ArrayOfMediaControl
            single={false}
            defaultValue={filledData?.[propertyName]}
            handleFilledData={handleFilledData}
            propertyName={propertyName}
          />
        );
      case 'media':
        return (
          <ArrayOfMediaControl
            single={true}
            defaultValue={filledData?.[propertyName]}
            handleFilledData={handleFilledData}
            propertyName={propertyName}
          />
        );
      case 'arrayOfTextLongTextMediaControl':
        return (
          <ArrayOfTextLongTextMediaControl
            single={false}
            defaultValue={filledData?.[propertyName]}
            handleFilledData={handleFilledData}
            propertyName={propertyName}
          />
        );
      case 'arrayOfTextMediaControl':
        return (
          <ArrayOfTextMediaControl
            single={false}
            defaultValue={filledData?.[propertyName]}
            handleFilledData={handleFilledData}
            propertyName={propertyName}
          />
        );
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Label htmlFor={id}>{label}</Label>
      <Select id={id} value={pickedTypeId} onChange={handleSelectChange}>
        <option value="" disabled>
          Select an option
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <Container>
        {pickedType?.map?.map((pt: any) =>
          ComponentRender({
            dataType: pt?.dataType,
            propertyName: pt?.propertyName,
          })
        )}
      </Container>
      <PreviewImage src={pickedType?.image} alt="" />
    </Wrapper>
  );
};

export default withJsonFormsControlProps(ContentTypeRendererv2);
