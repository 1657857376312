import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        margin:0;
        padding:0;
        box-sizing:border-box;
    }
    html {
        font-size:62.5%;
    }
    body {
        font-family: 'Roboto', sans-serif;
        font-size:1.6rem;
        background:  #FFF;
   
    }
    button {
        border:0;
        
    }
    .tox-notifications-container,.tox-statusbar__branding {
        display:none;
    }
`;
