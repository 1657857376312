import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';

export const outletSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.inforisks.com' }),
  tagTypes: [],
  endpoints: (builder) => ({
    getPage: builder.query({
      query: (slug: string) => `/v2/cms/page?slug=${slug}`,
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetPageQuery } = outletSlice;
