function decrementBySteps(start: number, steps: number[]) {
  const result = [start];
  for (let i = 1; i < steps.length; i++) {
    const diff = steps[i - 1] - steps[i];
    const last = result[result.length - 1];
    const next = last - diff;
    if (next >= steps[i]) {
      result.push(next);
    } else {
      break;
    }
  }
  if (result[result.length - 1] > 0) {
    result.push(0);
  }
  return result;
}

function decrementByMax(start: number, max: number, numSteps: number) {
  if (max === 0) return;
  const stepSize = max / (numSteps - 1);
  const steps = [];
  for (let i = 0; i < numSteps - 1; i++) {
    const stepValue = Math.max(max - i * stepSize, 0);
    steps.push(stepValue);
  }
  steps.push(0);
  return decrementBySteps(start, steps);
}
// obj
//     ? obj?.sort((a: any, b: any) => b?.count - a?.count)[0]?.[property]
//     : 0;
const handleScore = (obj: any, property: string) => {
  const highest = 1000;
  return {
    highest: highest,
    steps: decrementByMax(highest, highest, 5),
  };
};

export { handleScore };
