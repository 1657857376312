/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import ArrowDown from '../../assets/arrow-down.svg';
import ArrowUp from '../../assets/arrow-up.svg';

const Wrapper = styled.div`
  position: relative;
`;
const Title = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(73, 85, 100, 0.8);
`;

const OptionDisclaimer: any = styled.p`
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  overflow: hidden;
  color: ${({ activeColor }: any) =>
    activeColor ? '#333' : 'rgba(73, 85, 100, 0.6)'};
`;

const OptionInput = styled.button`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 36px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 3px 0px #09076a59;
  margin-top: 1rem;
  cursor: pointer;
  padding: 0 1.5rem;
  overflow: hidden;
`;

export const OptionIcon = styled.img``;

const Dropdown = styled.div`
  display: grid;
  margin-top: 1rem;
  width: 250px;
  box-shadow: -4px 0px 15px 3px #0000000d;
  border-radius: 5px;
  background: #fff;
  overflow: auto;
  max-height: 300px;
  position: absolute;
  z-index: 9999;
`;
const DropdownItem = styled.button`
  display: block;
  width: 100%;
  border: 0;
  text-align: left;
  //styleName: small text regular;
  font-family: Roboto;
  font-size: 12px;
  cursor: pointer;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #293748;
  background: #fff;
  padding: 0.6rem 1.5rem;
  text-transform: capitalize;
  &:hover {
    background: #e4f6fc;
  }
`;

interface IFilters {
  field: string;
  operator: string;
  value: string | number;
}

interface IFilter {
  title: string;
  data: any[];
  propertyName: string;
  setFilters: React.Dispatch<React.SetStateAction<IFilters[]>>;
  filters: IFilters[] | undefined;
  single: boolean;
  operator: string;
  customOptions: any;
  origin: string;
  findInObjectProperty: string;
  setFechtedData: any;
  dynamicQuery: any;
  handleParamReplace: any;
}

const Filter: React.FC<IFilter> = ({
  title,
  data,
  propertyName,
  setFilters,
  filters,
  single,
  operator,
  customOptions,
  origin,
  findInObjectProperty,
  setFechtedData,
  handleParamReplace,
  dynamicQuery,
}) => {
  const pushPage = useNavigate();
  const [optionList, setOptionList] = useState<any[]>();
  const [dropdownActive, setDropdownActive] = useState(false);
  const [onCustomSelect, setOnCustomSelect] = useState('');
  const location = window.location.pathname?.replace(
    '/management/app/analytics',
    ''
  );

  const setFilterValueByLocation = () => {
    return customOptions
      ?.map((cop: any) => {
        if (
          location
            ?.toLocaleLowerCase()
            ?.includes(cop?.addRouteParam?.toLowerCase())
        ) {
          return cop?.value;
        }
        return false;
      })
      .find((item: string) => item);
  };

  const getUniqueOptions = useCallback(() => {
    const uniqueOptions: any[] = customOptions?.length
      ? []
      : [{ value: 'Clear', field: propertyName }];
    // filter each unique item.
    if (!customOptions?.length) {
      data?.forEach((item) => {
        // Push only once, unique
        if (
          !uniqueOptions?.find((unique) => unique?.value === item[propertyName])
        ) {
          uniqueOptions.push({
            value: item[propertyName],
            field: propertyName,
          });
        }
      });
    }
    // push custom options if exists
    if (customOptions?.length > 0) {
      customOptions?.forEach((cop: any) => {
        return uniqueOptions.push({
          value: cop?.value,
          field: propertyName,
          route: cop?.addRouteParam || false,
          body: cop?.body || false,
        });
      });
    }

    setOptionList(uniqueOptions);
  }, [customOptions, data, propertyName]);

  // handle duplicate update
  const handleFilter = (field: string, value: string) => {
    // When you press clear, remove object in the array
    if (value === 'Clear') {
      const filteredFilter = filters?.filter((flt) => flt?.field !== field);
      // Remove fields that turned clear
      setFilters(filteredFilter ?? []);
      // To show all, set default
      if (filters && filters?.length <= 1) {
        setFilters([{ operator, value: 'all', field: 'all' }]);
      }
    }
    // Add new field's value
    if (value !== 'Clear') {
      const filteredFilter = filters?.filter((flt) => flt?.field !== field);
      // Clear if same field exists to avoid duplicates
      setFilters(filteredFilter ?? []);
      setFilters((prev) => [...prev, { operator, field, value }]);
    }
  };

  const pushIntoFilter = useCallback(
    (field: string, value: string) => {
      filters?.find((filt: any) => filt?.field === 'all') && value !== 'Clear'
        ? setFilters([{ operator, field, value }])
        : handleFilter(field, value);

      if (single) {
        setDropdownActive(false);
      }
    },
    [filters, handleFilter, operator, setFilters, single]
  );

  const fetchOnCustomItemEndpoint = (body: any) => {
    dynamicQuery({
      endpoint: handleParamReplace(),
      method: 'POST',
      body,
    });
  };

  useEffect(() => {
    // Remove selects on new fitler load
    setOnCustomSelect('');
    getUniqueOptions();
  }, [getUniqueOptions]);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <OptionInput onClick={() => setDropdownActive(!dropdownActive)}>
        <OptionDisclaimer
          activeColor={
            setFilterValueByLocation() ||
            filters?.find((f) => f.field === propertyName)?.value ||
            onCustomSelect
          }
        >
          {setFilterValueByLocation() ||
            filters?.find((f) => f.field === propertyName)?.value ||
            onCustomSelect ||
            'Click to select'}
        </OptionDisclaimer>
        <OptionIcon src={dropdownActive ? ArrowDown : ArrowUp} alt="" />
      </OptionInput>
      {dropdownActive && (
        <Dropdown>
          {optionList?.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={() => {
                if (option?.body) {
                  setFechtedData([]);
                  fetchOnCustomItemEndpoint(option?.body);
                  setOnCustomSelect(option.value);
                  return setDropdownActive(false);
                }
                if (option?.route) {
                  setDropdownActive(false);
                }
                setOnCustomSelect(option.value);
                option?.route
                  ? pushPage(origin + option?.route)
                  : pushIntoFilter(option.field, option.value);
              }}
            >
              {option?.value}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default Filter;
