import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authSlice } from './api/authSlice';
import globalSlice from './state/globalSlice';
import { outletSlice } from './api/outletSlice';

export const store = configureStore({
  reducer: {
    [authSlice.reducerPath]: authSlice.reducer,
    [outletSlice.reducerPath]: outletSlice.reducer,
    global: globalSlice,
  },
  middleware: (gDM: any) =>
    gDM().concat(authSlice.middleware, outletSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
