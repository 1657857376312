import React from 'react';
import styled from 'styled-components';
import { handleScore } from '../../utils/getHighestScore';

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5rem;
`;
const BarItem: any = styled.div`
  width: 50px;
  height: ${({ height }: any) => `${height}%`};
  background: ${({ color }) => color};
  position: relative;
  z-index: 999;
  border-radius: 5px;
  display: grid;
  justify-items: center;
`;
const BarLabel = styled.p`
  width: 200px;
  position: absolute;
  bottom: -23px;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: rgba(73, 85, 100, 0.8);
`;

const colorPalette = [
  '#5FA5DC',
  '#A1BDD4',
  '#D2E7F6',
  '#5986AB',
  '#B2DDFF',
  '#5B7182',
  '#5FA5DC',
  '#A1BDD4',
  '#D2E7F6',
  '#5986AB',
  '#B2DDFF',
  '#5B7182',
];

interface Iprops {
  obj: any;
  barChartProperty: string;
}

const Bar: React.FC<Iprops> = ({ obj, barChartProperty }) => {
  const highestScore = handleScore(obj, 'count').highest;
  const maxValue = Math.round(highestScore + highestScore / 12);
  const getPercent = (maxValue: number, barValue: number) => {
    return 100 - ((maxValue - barValue) / maxValue) * 100;
  };
  console.log(obj);

  return (
    <Wrapper>
      {obj?.slice(0, 8)?.map((ctx: any, index: number) => (
        <BarItem
          key={index}
          color={colorPalette[index]}
          height={getPercent(highestScore, ctx?.count)}
        >
          <BarLabel title={ctx[barChartProperty || 'activity']}>
            {ctx[barChartProperty || 'activity']?.length > 12
              ? ctx[barChartProperty || 'activity']?.slice(0, 12) + '...'
              : ctx[barChartProperty || 'activity']}
          </BarLabel>
        </BarItem>
      ))}
    </Wrapper>
  );
};

export default Bar;
