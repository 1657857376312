import React from 'react';
import Background from './Background';
import Bar from './Bar';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 1100px;
`;

interface Iprops {
  obj?: any;
  barChartProperty: string;
}

const Index: React.FC<Iprops> = ({ obj, barChartProperty }): any => {
  return (
    <Wrapper>
      <Background obj={obj}>
        <Bar obj={obj} barChartProperty={barChartProperty} />
      </Background>
    </Wrapper>
  );
};

export default Index;
