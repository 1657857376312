function createFilterFunction(filters: any) {
  // Use eval() to dynamically create a filter function based on the given filters
  const filterFunction = new Function(
    'row',
    `return ${filters
      ?.map(
        (filter: any) =>
          `(row.${filter.field} ${filter.operator} "${filter.value}")`
      )
      .join(' && ')}`
  );

  // Return the filter function
  return filterFunction;
}

export { createFilterFunction };
