import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';

export const authSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: config.API_MAIN }),
  keepUnusedDataFor: 30,
  tagTypes: [],
  endpoints: (builder) => ({
    logUser: builder.mutation({
      query(code: string) {
        return {
          url: `user/login`,
          method: 'POST',
          body: {
            code,
          },
        };
      },
    }),
    subpages: builder.query({
      query: (listType: string) => `list-types?type=${listType}`,
    }),
    getUser: builder.mutation({
      query(token: string) {
        return {
          url: `user/me`,
          method: 'POST',
          body: {
            token,
            channelIndex: Number(localStorage.getItem('channelIndex')) || 0,
            sourceGroupIndex:
              Number(localStorage.getItem('sourceGroupIndex')) || 0,
            path: window.location.pathname,
            appId: 'partnerPortal',

            url: window.location?.host,
          },
        };
      },
    }),
    dynamicQuery: builder.mutation<
      any,
      { endpoint: string; method: string; body: object }
    >({
      query({ endpoint, method, body }) {
        return {
          url: `${endpoint}`,
          method,
          body: {
            token: localStorage.getItem('token'),
            channelIndex: Number(localStorage.getItem('channelIndex')) || 0,
            sourceGroupIndex:
              Number(localStorage.getItem('sourceGroupIndex')) || 0,
            path: window.location.pathname,
            appId: 'partnerPortal',
            url: window.location?.host,
            ...body,
          },
        };
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useLogUserMutation,
  useGetUserMutation,
  useDynamicQueryMutation,
  useSubpagesQuery,
} = authSlice;
