/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import { ReadOnly } from './ReadOnlyStyle';
import { InputField } from './ArrayItemControl';

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const Select: any = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const PickedInputs = styled.div`
  display: grid;
  gap: 0.5rem;
  margin: 0 7rem;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  width: 600px;
  text-align: right;
  gap: 1rem;
`;
const TypeLabel = styled.label`
  color: #333;
  font-size: 1.4rem;
`;

interface DropdownControlProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const CustomReportFunctionType: React.FC<DropdownControlProps> = (props) => {
  const [options, setOptions] = useState<
    Array<{ label: string; value: string; dataType: any }>
  >([]);
  const [selectedOption, setSelectedOption] = useState<any>(''); // Added state for selected option
  const [pickedTypes, setPickedTypes] = useState<any>([]);
  const [pickedDataTypes, setPickedDataTypes] = useState<any>([]);
  const [pickedArg, setPickedArg] = useState('');
  const [values, setValues] = useState<any>([]);
  const [dynamicQuery, result] = useDynamicQueryMutation();
  const global = useAppSelector(selectGlobal);

  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: `/getDataFunctions`,
        method: 'POST',
        body: {},
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleValues = (property: string, value: string) => {
    let boolean = false;
    if (value === 'true') {
      boolean = true;
    }
    setValues((prev: any) => ({
      ...prev,
      [property]: value === 'true' || value === 'false' ? boolean : value,
    }));
  };

  const handlePickedArg = (arg: any) => {
    setPickedArg(arg);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      setOptions(
        result?.data?.data?.map((item: any) => ({
          label: item?.name,
          value: item?.arguments?.map((it: any) => it?.value),
          dataType: item?.arguments?.map((it: any) => it?.type),
        }))
      );
    }
  }, [result]);

  useEffect(() => {
    // Handling initial values
    if (global.rowObject?.functionType) {
      setSelectedOption(global.rowObject?.functionType);
    }

    if (global.rowObject?.functionArgs) {
      setPickedTypes(Object.keys(global.rowObject?.functionArgs));
      setPickedArg(global.rowObject?.functionType);
      setValues(global.rowObject?.functionArgs);
    }
  }, [global.rowObject]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectedLabel = options.find(
      (option) => option.value === selectedValue
    )?.label;
    setSelectedOption(selectedLabel);
    handlePickedArg(selectedValue);
    setPickedTypes(selectedValue?.split(','));
    setPickedDataTypes(options[event.target?.selectedIndex - 1]?.dataType);
    props.handleChange(props.path, selectedValue);
  };

  useEffect(() => {
    props.handleChange(props.path, {
      functionType: pickedArg,
      functionArgs: values,
    });
  }, [values, pickedArg]);

  return (
    <ReadOnly readOnly={props?.uischema?.options?.readOnly}>
      <Label htmlFor={props.id}>Function Type</Label>
      <Select name={props.id} id={props.id} onChange={handleSelectChange}>
        <option value="">Please select...</option>{' '}
        {options.map((option: any, index) => (
          <option
            onClick={() => handlePickedArg(option?.label)}
            key={index}
            value={option.value}
            selected={selectedOption === option?.label}
          >
            {option.label}
          </option>
        ))}
      </Select>
      <PickedInputs>
        {pickedTypes &&
          !pickedTypes?.includes('') &&
          pickedTypes.map((type: any, index: number) =>
            pickedDataTypes[index] === 'boolean' ||
            (typeof (
              global.rowObject?.functionArgs &&
              (Object.values(global.rowObject?.functionArgs)?.[index] as string)
            ) === 'boolean' &&
              pickedArg === global.rowObject?.functionType) ? (
              <InputContainer key={index}>
                <TypeLabel>{type}</TypeLabel>
                <Select
                  defaultValue={
                    global.rowObject?.functionArgs &&
                    (Object.values(global.rowObject?.functionArgs)?.[
                      index
                    ] as string)
                  }
                  onChange={(e: any) => handleValues(type, e.target.value)}
                >
                  <option>Select an option...</option>
                  <option>true</option>
                  <option>false</option>
                </Select>
              </InputContainer>
            ) : (
              <InputContainer key={index}>
                <TypeLabel>{type}</TypeLabel>
                <InputField
                  defaultValue={
                    global.rowObject?.functionArgs &&
                    (Object.values(global.rowObject?.functionArgs)?.[
                      index
                    ] as string)
                  }
                  onChange={(e) => handleValues(type, e.target.value)}
                  type="text"
                />
              </InputContainer>
            )
          )}
      </PickedInputs>
    </ReadOnly>
  );
};

export default withJsonFormsControlProps(CustomReportFunctionType);
